import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
} from "reactstrap";
import Flatpickr from "react-flatpickr";

import { toast, ToastContainer } from "react-toastify";
import { IMG_API_URL } from "../../helpers/url_helper";
import DeleteModal from "../../Components/Common/DeleteModal";
import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const NotificationTableView = () => {
  const {id} = useParams();
  const {type} = useParams();
  const [filterData, setfilterData] = useState({
    start_date: new Date().toISOString().split("T")[0] + " 00:00:00",
    end_date: new Date().toISOString().split("T")[0] + " 23:59:59",
    customer_name: 0,
  });
  const [modalStates, setModalStates] = useState(false);
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);
  const { http } = AuthUser();
  const [counts, Setcounts] = useState(1);
  const [LeadData, SetLeadData] = useState([]);
  // infinity
  const [Pages, SetPages] = useState(1);
  const [NoMore, SetNoMore] = useState(true);

  const [sourceData, setSourceData] = useState([]);
  const getSourceData = async () => {
    http
      .get("/source/list")
      .then((response) => {
        if (response.data.length > 0) {
          setSourceData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [tagsData, settagsData] = useState([]);
  const gettagsData = async () => {
    http
      .get("/tags/list")
      .then((response) => {
        if (response.data.length > 0) {
          settagsData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [assignedByData, setassignedByData] = useState([]);
  const getassignedByData = async () => {
    http
      .get("/user/list")
      .then((response) => {
        if (response.data.length > 0) {
          setassignedByData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleFilter = async () => {
    http
      .post(`/leads/filter`, filterData)
      .then(function (response) {
        if (response.data.length > 0) {
          SetLeadData(response.data);
        } else {
          SetLeadData([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [customerData, setcustomerData] = useState([]);
  const getcustomerData = async () => {
    http
      .get("/customer/list")
      .then((response) => {
        if (response.data.length > 0) {
          setcustomerData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [Notification,SetNotification]= useState([]);
  
  const NotificationView = () =>{
if (type === "1")
{
  http.get(`/attendance/show/${id}`)
  .then((res)=>{
    SetNotification(res.data);
    console.log(res.data);
  }).catch((e)=>{
    console.log(e);
  })
}else if (type === "2") {
  http.get(`/leads/show/${id}`)
  .then((res)=>{
    SetNotification(res.data);
    console.log(res.data);
  }).catch((e)=>{
    console.log(e);
  })
}
  }
  useEffect(() => { 
    NotificationView();


  }, [id]);
  const fetchData = () => {
    Setcounts(counts + 1);
  };
  //   Delete Aleart
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, SetID] = useState();
  const onClickDelete = (data) => {
    SetID(data);
    setDeleteModal(true);
  };
  const handleDeleteOrder = (data) => {
    if (data._reactName == "onClick") {
      http
        .delete(`/lead/delete/${ID}`)
        .then(function (response) {
          if (response.data.status == 0) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          Setcounts(counts + 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    SetLeadData([]);
    SetPages(1);
    setDeleteModal(false);
  };

  // shortcuts for opening add form
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.altKey && event.key === "a") ||
        (event.altKey && event.key === "A")
      ) {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //   end Alert
  const handleCallback = (data, status) => {
    SetLeadData([]);
    SetPages(1);
    if (status == 1) {
      setModalStates(false);
      setUpdateModalStates(false);
      toast.success(data);
      Setcounts(counts + 1);
    } else {
      toast.warn(data);
      Setcounts(counts + 1);
    }
  };
  // Edit Data
  const [FindData, SetFind] = useState([]);
  const EditUpdate = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };
  const downloadCSV = () => {
    // const table = document.getElementById('tableToPrint');
    // const rows = table.querySelectorAll('tr');
    // let csvContent = 'data:text/csv;charset=utf-8,';
    // rows.forEach((row) => {
    //   const rowData = [];
    //   const cols = row.querySelectorAll('td, th');
    //   cols.forEach((col, index) => {
    //     rowData.push(index === cols.length - 1 ? col.textContent : `${col.textContent},`);
    //   });
    //   csvContent += rowData.join('') + '\n';
    // });
    // const encodedURI = encodeURI(csvContent);
    // const link = document.createElement('a');
    // link.setAttribute('href', encodedURI);
    // link.setAttribute('download', 'leads.csv');
    // document.body.appendChild(link);
    // link.click();
  };

  const calculateDuration = (inTime, outTime) => {
    const inTimeDate = new Date(`2024-01-03 ${inTime}`);
    const outTimeDate = new Date(`2024-01-03 ${outTime}`);
    const timeDiffMilliseconds = outTimeDate - inTimeDate;
    const timeDiffHours = timeDiffMilliseconds / (1000 * 60 * 60);
    return timeDiffHours.toFixed(2); // Display hours with two decimal places
  };
  // Function to format time to 12-hour format
  const formatTime = (timeString) => {
    if (!timeString) return '';

    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(parseInt(hours, 10), parseInt(minutes, 10));

    return new Intl.DateTimeFormat('en-US', options).format(date);
  };
  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    {
                      type === "1" ? ( <>  <h5 className="card-title mb-0">Attendance View</h5></>):type === "2"?(<><h5 className="card-title mb-0">Lead View</h5></>):(<></>)
                    }
                  
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <div>
     
                      </div>
                      <div>
                        
                      </div>
                      <div>
                      
                      </div>
                      <div>
                      </div>
                      <div>
                        {/* <Flatpickr
                          className="form-control fw-bold"
                          options={{
                            dateFormat: "d/m/Y",
                            defaultDate: "today",
                          }}
                          name="payment_date"
                          placeholder="Enter start date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];
                            // Convert to YYYY-MM-DD format
                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day} 23:59:59`;

                            setfilterData({
                              ...filterData,
                              end_date: formattedDate,
                            });
                          }}
                        /> */}
                      </div>
                      <div>
                        {/* <button
                          className="btn btn-success "
                          onClick={(e) => {
                            handleFilter();
                          }}
                        >
                          Search
                        </button> */}
                      </div>
                      {/* <button
                        type="button"
                        className="btn btn-info"
                        onClick={() => downloadCSV()}
                      >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        Export
                      </button> */}
                      {/* <button
                        type="button"
                        className="btn fw-bold btn-success add-btn"
                        id="create-btn"
                        onClick={() => setModalStates(!modalStates)}
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Add
                        Lead
                      </button> */}
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
{
  type === "1" ? (
   <table
                    role="table"
                    className="align-middle table-nowrap table table-hover"
                  >
                   <thead className="table-light text-muted text-uppercase">
                        <tr>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            #
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Date
                          </th>

                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            IN TIME
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            IN LOCATION
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            IN PHOTO
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            STATUS
                          </th>
                        </tr>
                      </thead>
                    <tbody>
                          <tr >
                            <td>
                              <a
                                className="fw-medium link-primary"
                                href="/apps-ecommerce-order-details"
                              >
                              </a>
                            </td>
                            <td>{Notification.attendance_date}</td>
                            <td>{formatTime(Notification.in_time)}</td>
                            <td style={{ "maxWidth": "150px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>{Notification.in_location}</td>
                            <td><img src={IMG_API_URL + Notification.in_photo} style={{ height: '100px', width: '100px' }} /></td>


                            <td>{Notification.remark == 1 ? (<button
                              type="button"
                              className="btn fw-bold btn-success add-btn"
                              id="create-btn"
                            // onClick={() => setModalStates(!modalStates)}
                            >
                              <i className="ri--line align-bottom me-1"></i>Present
                            </button>) : (<button
                              type="button"
                              className="btn fw-bold btn-danger add-btn"
                              id="create-btn"
                            // onClick={() => setModalStates(!modalStates)}
                            >
                              <i className="ri--line align-bottom me-1"></i>Absent
                            </button>)}</td>


                          </tr>
                    </tbody>
                  </table> 
  ) : type === "2" ? (<>
    <table
                    role="table"
                    className="align-middle table-nowrap table table-hover"
                  >
                   <thead className="table-light text-muted text-uppercase">
                        <tr>
                        <th title="Toggle SortBy" style={{ cursor: "pointer" }}>
                          Index
                        </th>
                        <th title="Toggle SortBy" style={{ cursor: "pointer" }}>
                          Customer Name
                        </th>
                        <th>Phone</th>
                        <th>City Name</th>
                        <th>Product </th>
                        <th>Value</th>
                        <th>Tags</th>
                        <th>Assigned</th>
                        <th>Source</th>
                        <th>Created</th>
                        </tr>
                      </thead>
                    <tbody>
                    {Notification.length > 0 ? (
                        Notification.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <a
                                className="fw-medium link-primary"
                                href="/apps-ecommerce-order-details"
                              >
                                {index + 1}
                              </a>
                            </td>
                            <td>{item.customer_name}</td>
                            <td>{item.customer_whatsapp_no}</td>
                            <td>{item.city_name}</td>
                            <td>{item.product_name}</td>
                            <td>{item.value}</td>
                            <td>
                              {tagsData != null && item.tags
                                ? tagsData.map((e) =>
                                  JSON.parse(item.tags).includes(e.tag_id) ? (
                                    <div key={e.tag_id} className="p-1">
                                      <button>{e.tag_name}</button>
                                    </div>
                                  ) : null
                                )
                                : "Loading..."}
                            </td>
                            <td>
                              {assignedByData != null && item.assigned_by
                                ? assignedByData.map((e) =>
                                  JSON.parse(item.assigned_by).includes(
                                    e.uid
                                  ) ? (
                                    <div key={e.uid} className="p-1">
                                      <button>{e.name}</button>
                                    </div>
                                  ) : null
                                )
                                : "Loading..."}
                            </td>
                            <td>{item.source_name}</td>
                            {/* <td>{item.lead_status_name}</td> */}
                            <td>
                              {Math.floor(
                                (new Date() - new Date(item.today_date)) /
                                (1000 * 60 * 60)
                              )}{" "}
                              hrs ago
                            </td>

                            <td>
                              <ul className="list-inline hstack gap-2 mb-0">
                                <li className="list-inline-item edit">
                        
                                </li>
                                {/* <li className="list-inline-item">
                                  {item.lead_id !== 1 ? (
                                    <button
                                      onClick={() =>
                                        onClickDelete(item.lead_id)
                                      }
                                      className="text-danger d-inline-block remove-item-btn  border-0 bg-transparent"
                                    >
                                      <i className="ri-delete-bin-5-fill fs-16" />
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </li> */}
                              </ul>
                            </td>
                          </tr>
                        ))
                      ) : ""}
                    </tbody>
                  </table> 
  </>) : type === "3"?(<></>):(<></>)
}
              
                </div>

   
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotificationTableView;
