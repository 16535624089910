import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Form,
  Button,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import AuthUser from "../../helpers/Authuser";
import CustomInput from "../Unit/Input";
import { toast } from "react-toastify";


const CustomerUpdate = (props) => {
  // On form submit getCustomerData imported
  const { http } = AuthUser();
  // console.log(manageCity);


  const [CustomersData, setCustomersData] = useState(props.edit_data);
  // console.log(CustomersData);
  const getCustomerData = (e) => {
    setCustomersData({ ...CustomersData, [e.target.name]: e.target.value });
    if (CustomersData.customer_name != "") {
      setCheckStatus({});
      setMsg("");
    }
  };

  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");
  const OnSubmited = () => {
    if (CustomersData.customer_name == "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Customer connot be empty!");
    } else {
      http
        .put("/customer/update", CustomersData)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const [modal, setModal] = useState(false);

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);





  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <Modal id="showModal" size="xl" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Update Customer
        </ModalHeader>
        <div className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              OnSubmited();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success  p-3 shadow-lg">
                <div className="mb-3 row">
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      <div>
                        Full Name<span style={{ color: "red" }}> *</span>
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <CustomInput
                      checkNameStatus={checkNameStatus}
                      onChange={getCustomerData}
                      value={CustomersData.customer_name}
                      name="customer_name"
                      id="customer_name"
                      className="form-control fw-bold"
                      placeholder="Customer Name"
                      type="text"
                    />
                  </div>

                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold"
                    >
                      <div>WhatsApp Number</div>
                    </Label>
                    <Input
                      onChange={getCustomerData}
                      value={CustomersData.customer_whatsapp_no}
                      name="customer_whatsapp_no"
                      id="customer_whatsapp_no"
                      className="form-control fw-bold"
                      placeholder="Mobile No"
                      type="text"
                    />
                  </div>
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold"
                    >
                      Alternative Mobile Number
                    </Label>
                    <Input
                      onChange={getCustomerData}
                      value={CustomersData.customer_alternative_no}
                      name="customer_alternative_no"
                      id="customer_alternative_no"
                      className="form-control fw-bold"
                      placeholder="customer alternativ Mobile No"
                      type="text"
                    />
                  </div>
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold"
                    >
                      Email.
                    </Label>
                    <Input
                      onChange={getCustomerData}
                      value={CustomersData.customer_email}
                      name="customer_email"
                      id="customer_email"
                      className="form-control fw-bold"
                      placeholder="Email"
                      type="text"
                    />
                  </div>


                  <div className="col-12">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-3 fw-bold"
                    >
                      Address
                    </Label>
                    <textarea
                      onChange={getCustomerData}
                      value={CustomersData.customer_address}
                      rows={3}
                      placeholder="Enter address"
                      className="form-control fw-bold"
                      name="customer_address"
                      id="customer_address"
                    />
                  </div>
                </div>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <div className="hstack gap-2 justify-content-end">

                {/* {activeGroup ? ( */}
                <button
                  type="sumbit"
                  name="sumbit"
                  id="submit"
                  className="btn btn-primary"
                  // onClick={() => OnSubmited()}
                  ref={submitButtonRef}
                >
                  <i className="ri-save-3-line align-bottom me-1"></i>
                  Update
                </button>
                <button
                  name="close"
                  id="close"
                  type="button"
                  className="btn btn-danger"
                  onClick={() => Close()}
                >
                  <i className="ri-close-line me-1 align-middle" />
                  Close
                </button>

              </div>
            </div>
          </Form>
        </div>
      </Modal>

    </div>
  );
};

export default CustomerUpdate;
