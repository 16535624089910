import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Input } from "reactstrap";
import Sortable from 'sortablejs';
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";

import CustomInput from "../Unit/Input";

const RecentOrders = () => {
  const { http, user } = AuthUser();
  const example2LeftRef = useRef(null);
  const example2RightRef = useRef(null);
  const [todoList, setTodoList] = useState([]);
  const [counts, Setcounts] = useState(1);

  const fetchTodoList = async () => {
    try {
      const response = await http.get(`/todo/list/${user.user.uid}`);
      setTodoList(response.data);
    } catch (error) {
      console.error("Error fetching todo list:", error);
    }
  };

  const updateTodoStatus = async (id, newStatus) => {
    // console.log(newStatus);
    try {
      await http.put(`/todo/delete`, { todo_id: id, todo_status: newStatus }).then((res) => {
        if (res.data.status === 1) {
          toast.warn(res.data.message);
        } else if (res.data.status === 2) {

          toast.success(res.data.message);
        } else {
          toast.warn(res.data.message);
        }
        // Setcounts(counts + 1);

        // console.log(res.data);

      });
    } catch (error) {
      console.error(`Error deleting todo status for id ${id}:`, error);
    }
  };

  const getTodoId = (element) => {
    return element.getAttribute('data-todo-id');
  };

  const handleDrop = (event) => {
    const { item } = event;
    const draggedTodoId = item.getAttribute('data-todo-id');
    // console.log(draggedTodoId);
    const newStatus = event.to === example2LeftRef.current ? 1 : 2 && event.to === example2RightRef.current ? 2 : 1;
    updateTodoStatus(draggedTodoId, newStatus);
  };

  // Initialize Sortable and handle drop events
  useEffect(() => {
    fetchTodoList();

    if (example2LeftRef.current && example2RightRef.current) {
      const leftSortable = new Sortable(example2LeftRef.current, {
        group: 'shared',
        animation: 150,
        onEnd: (event) => handleDrop(event, 1), // Pass status 1 for left list
      });

      const rightSortable = new Sortable(example2RightRef.current, {
        group: 'shared',
        animation: 150,
        onEnd: (event) => handleDrop(event, 2), // Pass status 2 for right list
      });

      return () => {
        leftSortable.destroy();
        rightSortable.destroy();
      };
    }
  }, [counts]);
  const [modalStates, setModalStates] = useState(false);

  const handleCallback = (data, status) => {
    if (status == 1) {
      toast.success(data);
      Setcounts(counts + 1);
    } else {
      toast.warn(data);
      Setcounts(counts + 1);
    }
    setModalStates(false);
  };
  // shortcuts for opening add form
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "t" || event.altKey && event.key === "T") {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const [modal, setModal] = useState(false);
  const [Todo, SetTodo] = useState({
    todo_user_id: user && user.user.uid,
    todo_name: ''
  });

  // console.log(Todo);

  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");
  const SubmitData = () => {
    if (Todo.todo_name == "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Todo connot be empty!");
    } else {
      http
        .post("/todo/store", Todo)
        .then(function (response) {
          toast.success(response.data.message);
          // props.checkchang(response.data.message, response.data.status);
          Setcounts(counts + 1);
          SetTodo({ ...Todo, ['todo_name']: '' });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const handleTodo = (e) => {
    setCheckStatus({});
    setMsg("");
    SetTodo({ ...Todo, ['todo_name']: e.target.value });
  };
  return (
    <React.Fragment>
      <Col xl={4}>
        <Card >
          <CardHeader className="align-items-center d-flex">

            <div style={{ color: "red" }}>{msg}</div>
            <Input
              // checkNameStatus={checkNameStatus}

              name="todo_name"
              id="todo-field"
              className="form-control fw-bold"
              placeholder="Todo Name"
              type="text"
              // style={{ width: '20%' }}
              value={Todo.todo_name}
              onChange={handleTodo}
            />
            <Button
              className="btn btn-success btn-sm px-1"
              style={{ padding: "8px 10px", marginLeft: '10px' }}
              onClick={SubmitData}
            >
              Add
            </Button>
            {/* </div> */}
          </CardHeader>
          <CardBody style={{ height: '400px', overflowY: 'auto' }}>
            <h5 style={{ color: "orange" }}>Latest To Do's</h5>
            <div ref={example2LeftRef}>
              {todoList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .filter((item) => item.todo_status === 1)
                .map((item) => (
                  <div key={item.id} data-todo-id={item.todo_id} style={{ marginBottom: '10px', whiteSpace: 'nowrap' }}>
                    <input type="checkbox" style={{ marginRight: '10px' }} /> {
                      item.todo_name.split(' ').length > 10 ?
                        <>
                          {item.todo_name.split(' ').slice(0, 10).join(' ')}
                          <br />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.todo_name.split(' ').slice(10).join(' ')}
                        </> : item.todo_name
                    }
                  </div>

                ))}
            </div>
            <hr />
            <h5 style={{ color: "green" }}>Latest Finished To Do's</h5>
            <div ref={example2RightRef}>
              {todoList
                .filter((item) => item.todo_status === 2)
                .map((item) => (

                  <div key={item.id} data-todo-id={item.todo_id} style={{ marginBottom: '10px', textDecoration: 'line-through' }}>
                    <input type="checkbox" style={{ marginRight: '10px' }} defaultChecked />



                    {/* {item.todo_name} */}

                    {
                      item.todo_name.split(' ').length > 10 ?
                        <>
                          {item.todo_name.split(' ').slice(0, 10).join(' ')}
                          <br />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.todo_name.split(' ').slice(10).join(' ')}
                        </> : item.todo_name
                    }
                  </div>

                ))}
            </div>
          </CardBody>
        </Card>
      </Col>

    </React.Fragment>

  );
};

export default RecentOrders;
