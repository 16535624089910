import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";

import { useNavigate } from "react-router-dom";

const RechargeAdd = (props) => {
  const { https, http, user } = AuthUser();

  const [retailer, setRetailer] = useState([]);
  const getRetailer = async () => {
    http
      .get("/retailer/list")
      .then((res) => {
        setRetailer(res.data)
      })
      .catch((err) => {
        console.log(err);
      })
  }
  const [recharge, setRecharge] = useState({
    recharge_retailer_id: "",
    recharge_amount: "",
    recharge_date: new Date().toLocaleDateString('en-GB').split('/').reverse().join('-')
  });
  const navigate = useNavigate();

  useEffect(() => {
    getRetailer();
  }, [])
  const [tempValid, settempValid] = useState(0);
  const SubmitData = () => {
    if (recharge.recharge_retailer_id === "") {
      settempValid(1);
    } else if (recharge.recharge_amount === "") {
      settempValid(2);
    } else {
      http
        .post("/recharge/store", recharge)
        .then(function (response) {
          if (response.data.status === 1) {
            toast.success(response.data.message);
            navigate('/recharge-list');
          } else {
            toast.error(response.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      setRecharge({
        ...recharge,
        recharge_amount: ""
      })
    }
  };






  return (
    <div className="page-content">
      <Form


      >

        <Card className="border p-3 shadow-lg">
          <CardHeader className="card-header border-0">
            <Row className="align-items-center gy-3">
              <div className="col-sm">
                <h5 className="card-title mb-0">Recharge  List</h5>
              </div>
              <div className="col-sm-auto">
                <div className="d-flex gap-1 flex-wrap">


                </div>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg={6} className="mb-3">
                <Label
                  htmlFor="CandidateName-field"
                  className="form-label fw-bold d-flex justify-content-between"
                >
                  <div>Retailer Name</div>
                  <div className="text-danger fw-bold">{tempValid == 1 && "Select the retailer."}</div>
                </Label>
                <Select
                  className="fw-bold"
                  placeholder={"Retailer Name"}
                  options={retailer.filter(item => item.u_type == 3).map((data, index) => ({
                    value: data.uid,
                    label: data.name,
                  }))}
                  onChange={(e) => {
                    settempValid(0);
                    setRecharge({
                      ...recharge,
                      recharge_retailer_id: e.value
                    })
                  }}
                >

                </Select>
              </Col>
              <Col lg={6}>
                <Label
                  htmlFor="CandidateName-field"
                  className="form-label fw-bold d-flex justify-content-between"
                >
                  <div>Enter Amount to be Added in Wallet <span className="text-danger fw-bold">*</span></div>
                  <div className="text-danger fw-bold">{tempValid == 2 && "Enter the amount to add in Wallet."}</div>
                </Label>
                <Input
                  type="text"
                  className="fw-bold "
                  placeholder="Amount"
                  value={recharge.recharge_amount}
                  onChange={(e) => {
                    settempValid(0)
                    setRecharge({
                      ...recharge,
                      recharge_amount: e.target.value
                    })
                  }}
                />
              </Col>
              <Col lg={12}>
                <button
                  name="close"
                  id="close"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    SubmitData();
                  }}
                >
                  <i className="ri-save-3-line align-bottom me-1"></i>
                  Recharge Now
                </button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </div>
  );
};

export default RechargeAdd;
