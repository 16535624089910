import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    products: [],
    totalSalesPrice: 0,
    totalMRP: 0,
    totalDiscount: 0,
    totalBillAmount: 0,
    totalPayable: 0,
    totalRemaining: 0,
    totalQty: 0,
    isVisibilityForAmount: false,
    isVisibilityForSalePrice: false,
    isQtyEditable: false,
    invoiceDetails: {},
    isPrintingDialogOpening: false
};

const roundToTwoDecimalPlaces = (value) => Math.round(value * 100) / 100;

const posSlice = createSlice({
    name: 'POSSLICE',
    initialState: initialState,
    reducers: {
        addProductToStore: (state, action) => {
            state.products = [...state.products, ...action.payload];
        },
        deleteProductFromStore: (state, action) => {
            const productNoToDelete = action.payload;
            state.products = state.products.filter(product => product.product_id !== productNoToDelete);
        },
        setTotalAmounts: (state) => {
            state.totalSalesPrice = roundToTwoDecimalPlaces(0);
            state.totalMRP = roundToTwoDecimalPlaces(0);
            state.totalDiscount = roundToTwoDecimalPlaces(0);
            state.totalBillAmount = roundToTwoDecimalPlaces(0);
            state.totalQty = roundToTwoDecimalPlaces(0);
            state.totalPayable = roundToTwoDecimalPlaces(0);
            state.totalRemaining = roundToTwoDecimalPlaces(0);
            for (const product of state.products) {
                state.totalMRP += roundToTwoDecimalPlaces(product.mrp * product.qty);
                state.totalQty += roundToTwoDecimalPlaces(product.qty);
                state.totalSalesPrice += roundToTwoDecimalPlaces(product.salePrice * product.qty);
                state.totalBillAmount += roundToTwoDecimalPlaces(product.salePrice * product.qty);
                state.totalPayable += roundToTwoDecimalPlaces(product.salePrice * product.qty);
            }
        },
        updateSingleProduct: (state, action) => {
            const { product_id, newSalePrice } = action.payload;
            const productToUpdate = state.products.find(product => product.product_id === product_id);
            if (productToUpdate) {
                productToUpdate.salePrice = newSalePrice;
                productToUpdate.totalPrice = roundToTwoDecimalPlaces(newSalePrice * productToUpdate.qty);
            }
        },
        updateMRPPrice: (state, action) => {
            const { product_id, newMRPPrice } = action.payload;
            const productToUpdate = state.products.find(product => product.product_id === product_id);
            if (productToUpdate) {
                productToUpdate.mrp = newMRPPrice;
            }
        },
        updateMassQuantity: (state, action) => {
            const { product_id, newQty } = action.payload;
            const productToUpdate = state.products.find(product => product.product_id === product_id);
            if (productToUpdate) {
                productToUpdate.qty = newQty;
                productToUpdate.totalPrice = roundToTwoDecimalPlaces(newQty * productToUpdate.salePrice);
            }
        },
        increaseProductQuantity: (state, action) => {
            const { product_id } = action.payload;
            const productToUpdate = state.products.find(product => product.product_id === product_id);
            if (productToUpdate) {
                productToUpdate.qty = roundToTwoDecimalPlaces(productToUpdate.qty + 1);
                productToUpdate.totalPrice = roundToTwoDecimalPlaces(productToUpdate.qty * productToUpdate.salePrice);
            }
        },
        decreaseProductQuantity: (state, action) => {
            const { product_id } = action.payload;
            const productToUpdate = state.products.find(product => product.product_id === product_id);
            if (productToUpdate) {
                productToUpdate.qty = roundToTwoDecimalPlaces(productToUpdate.qty - 1);
                productToUpdate.totalPrice = roundToTwoDecimalPlaces(productToUpdate.qty * productToUpdate.salePrice);
            }
        },
        setVisibility: (state, action) => {
            state.isVisibilityForAmount = action.payload;
        },
        setVisibilityForSale: (state, action) => {
            state.isVisibilityForSalePrice = action.payload;
        },
        updatePayable: (state, action) => {
            state.totalPayable = roundToTwoDecimalPlaces(0);
            for (const product of state.products) {
                state.totalPayable += roundToTwoDecimalPlaces(product.salePrice * product.qty);
            }
            state.totalRemaining = roundToTwoDecimalPlaces(state.totalPayable - action.payload);
            state.totalPayable = roundToTwoDecimalPlaces(action.payload);
        },
        makeLastProductEditable: (state, action) => {
            state.isQtyEditable = action.payload;
        },
        removeAllProducts: (state, action) => {
            state.products = [];
        },
        addMassAssignment: (state, action) => {
            state.products = action.payload;
        },
        setInvoiceDetails: (state, action) => {
            state.invoiceDetails = action.payload
        },
        setPrintingDialogState: (state, action) => {
            state.isPrintingDialogOpening = action.payload
        },
        setTotalPayable: (state, action) => {
            state.totalPayable = action.payload
        },
        setTotalRemaining: (state, action) => {
            state.totalRemaining = action.payload
        }
    },
});

export const {
    addProductToStore,
    deleteProductFromStore,
    setTotalAmounts,
    updateSingleProduct,
    increaseProductQuantity,
    updateMRPPrice,
    decreaseProductQuantity,
    setVisibility,
    setVisibilityForSale,
    updatePayable,
    updateMassQuantity,
    makeLastProductEditable,
    removeAllProducts,
    addMassAssignment,
    setPrintingDialogState,
    setInvoiceDetails,
    setTotalPayable,
    setTotalRemaining
} = posSlice.actions;

export default posSlice.reducer;
