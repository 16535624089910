import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { IMG_API_URL } from "../../helpers/url_helper";


const ApplicationAdd = (props) => {
  const { https, http, user } = AuthUser();
  const [modal, setModal] = useState(false);

  const [uploadedImages, setUploadedImages] = useState({});

  const handleImageUpload = (event, documentId) => {
    const selectedImage = event.target.files[0];
    setUploadedImages({ ...uploadedImages, [documentId]: selectedImage });
  };


  const [serviceData, setServiceData] = useState(props.edit_data);
  const [application, setApplication] = useState({
    retailer_id: user.user.uid,
    application_customer_name: "",
    application_customer_email: "",
    application_customer_mobile: "",
    application_remark: "",
    application_amount: serviceData.service_amount,
    application_service_id: serviceData.service_id,
    application_assigned_to: 0,
  });

  const [documentData, setDocumentData] = useState([]);
  const getDocumentData = async () => {
    http
      .get("/service/list")
      .then((res) => {
        setDocumentData(res.data.master);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    getDocumentData();
  }, [])
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [tempValid, settempValid] = useState(0);
  const SubmitData = () => {
    if (application.application_customer_name === "") {
      settempValid(1);
    }
    else {
      const sendData = {
        ...application,
        documents: uploadedImages
      }
      if (Object.keys(sendData.documents).length < documentData.filter(item => item.service_id === serviceData.service_id && item.document_status == 2).length) {
        settempValid(4);
      } else {
        https
          .post("/application/apply", sendData)
          .then(function (response) {
            props.checkchang(response.data.message, response.data.status);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        Close();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);




  return (
    <div>
      <Modal
        className="modal-fullscreen"
        id="fullscreeexampleModal"
        isOpen={modal}
        size={"xl"}
        toggle={toggle}
        centered
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Create Application
        </ModalHeader>
        <span className="tablelist-form">
          <Form


          >
            <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>
                  <Col lg={6}>
                    <Row>
                      <Col lg={4}>
                        <img alt="" width={200} height={200} src={IMG_API_URL + "/service/" + serviceData.service_image} />
                      </Col>
                      <Col lg={8}>
                        <div className="mb-3">
                          <Label
                            htmlFor="CandidateName-field"
                            className="form-label fw-bold d-flex justify-content-between"
                          >
                            <div>Service Description</div>
                          </Label>
                          <CKEditor
                            disabled
                            rows={1}
                            data={serviceData.service_description}
                            editor={ClassicEditor}
                            onChange={(e, editor) => {
                              setApplication({
                                ...application,
                                follow_up_description: editor.getData(),
                              });
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="CandidateName-field"
                            className="form-label fw-bold d-flex justify-content-between"
                          >
                            <div>
                              Customer Name
                              <span style={{ color: "red" }}> *</span>
                            </div>
                            <div style={{ color: "red" }}>
                              {tempValid === 1 ? "Name cannot be empty!" : ""}
                            </div>
                          </Label>
                          <CustomInput
                            name="application_candidate_name"
                            id="CandidateName-field"
                            className="form-control fw-bold"
                            placeholder="Customer Name"
                            type="text"
                            onChange={(e) => {
                              settempValid(0);
                              setApplication({
                                ...application,
                                application_customer_name: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <Label
                            htmlFor="CandidateName-field"
                            className="form-label fw-bold d-flex justify-content-between"
                          >

                            Customer Email

                          </Label>
                          <Input
                            name="application_candidate_name"
                            id="CandidateName-field"
                            className="form-control fw-bold"
                            placeholder="Customer Email"
                            type="text"
                            onChange={(e) => {
                              settempValid(0);
                              setApplication({
                                ...application,
                                application_customer_email: e.target.value,
                              });
                            }}
                          />
                        </div>

                      </Col>
                      <Col lg={6}>

                        <div className="mb-3">
                          <Label
                            htmlFor="CandidateName-field"
                            className="form-label fw-bold d-flex justify-content-between"
                          >
                            Customer Mobile
                          </Label>
                          <Input
                            type="text"
                            className="fw-bold "
                            placeholder="Customer Mobile"
                            onChange={(e) => {
                              setApplication({
                                ...application,
                                application_customer_mobile: e.target.value,
                              });
                            }}
                          />
                        </div>



                        <div className="mb-3">
                          <Label
                            htmlFor="CandidateName-field"
                            className="form-label fw-bold d-flex justify-content-between"
                          >
                            <div>Remark</div>
                          </Label>
                          <Input
                            type="text"
                            className="fw-bold "
                            placeholder="Remark"
                            onChange={(e) => {
                              setApplication({
                                ...application,
                                application_remark: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </Col>


                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Label
                      htmlFor="CandidateName-field"
                      className="form-label fw-bold d-flex justify-content-between"
                    >
                      <div className="h4">
                        Upload Document's
                      </div>
                      <div style={{ color: "red" }}>
                        {tempValid === 4 ? "Ensure that all fields marked with an asterisk (*) are completed." : ""}
                      </div>
                    </Label>
                    <div className="row">
                      {documentData.filter(item => item.service_id == serviceData.service_id).map((data, index) => (
                        <div className="col-md-6   my-3" key={index}>
                          <div className="fw-bold h5">
                            <div>{data.document_name} <span className="text-danger">{data.document_status === 2 ?
                              `*   ` : ""}</span></div>
                            {data.document_description != '' ? (<div style={{ fontSize: '10px' }}>({data.document_description})</div>) : ''}
                          </div>
                          <div >
                            <input className="form-control" type="file"
                              accept="image/png, image/gif, image/jpeg, application/pdf"
                              onChange={(event) => handleImageUpload(event, data.document_id)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>

                  </Col>
                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <button
                ref={submitButtonRef}
                name="close"
                id="close"
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  SubmitData();
                }}
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                Purchase
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>

            </div>
          </Form>
        </span>
      </Modal>
    </div>
  );
};

export default ApplicationAdd;
