

import React from 'react';

import ReactModal from 'react-modal';
import Modal from 'react-modal';


import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



Modal.setAppElement('#root');

const ImgCom = ({ imageUrl }) => {
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }



    function closeModal() {
        setIsOpen(false);
    }



    return (
        <div>
            <img style={{ height: "100px", width: "100px" }} onClick={openModal} src={imageUrl} alt='' ></img>

            <ReactModal
                isOpen={modalIsOpen}
                contentLabel="Example Modal"
                onRequestClose={closeModal}

                style={{
                    content: {
                        width: "700px", // Set the desired width
                        height: "500px", // Set the desired height
                        top: "50%", // Center vertically
                        left: "50%", // Center horizontally
                        transform: "translate(-50%, -50%)", // Center the modal
                    },
                }}
            >
                <div className='text-center'>
                    <img src={imageUrl} alt='' style={{ height: "450px", width: "400px" }}></img>
                </div>
            </ReactModal>

        </div>
    );

}

export default ImgCom;
