import React, { useEffect, useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

//import images
import bell from "../../assets/images/svg/bell.svg";

//SimpleBar
import SimpleBar from "simplebar-react";
import AuthUser from '../../helpers/Authuser';
import NotificationView from './NotificationView';
import { io } from 'socket.io-client';
import sms from "../../assets/audio/sms.mp3";
import { API } from '../../helpers/url_helper';
const NotificationDropdown = () => {
    const { http, user } = AuthUser();
    const [modalStates, setModalStates] = useState(false);
    //Dropdown Toggle
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    //Tab 
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    const [countss, setcountss] = useState(0)
    const callbackfun = (id) => {
        http.get(`/notification/status/change/${id}`).then((res) => {
            console.log(res.data);
        }).catch((e) => {
            console.log(e);
        })
        setcountss(countss + 1)
    }
    const [NotificationLength, SetNotificationLength] = useState(null);
    const [Notification, SetNotification] = useState([]);

    const [ReadedNotification, SetReadedNotification] = useState([]);
    const [ReadedNotificationLength, SetReadedNotificationLength] = useState(0);
    useEffect(() => {
        http.get(`/notification/show/${user.user.uid}`).then((res) => {
            SetNotificationLength(res.data.length)
            SetNotification(res.data);

        }).catch((e) => {
            console.log(e);
        })
        http.get(`/notification/readed/${user.user.uid}`).then((res) => {
            SetReadedNotificationLength(res.data.length)
            SetReadedNotification(res.data)
        }).catch((e) => {
            console.log(e);
        })
    }, [countss])

    useEffect(() => {
        // Connect to the Socket.IO server
        const socket = io(API); // Adjust URL/port as needed
        let previousNotificationLength = parseInt(sessionStorage.getItem('previousNotificationLength') || '0', 10);
        // Listen for 'fetchNotifications' event emitted by the server
        socket.on('fetchNotifications', () => {
            // Call the "show" API to fetch notifications
            http.get(`/notification/show/${user.user.uid}`)
                .then((res) => {
                    SetNotificationLength(res.data.length)
                    SetNotification(res.data);
                    if (res.data.length > previousNotificationLength) {
                        const audio = new Audio(sms);
                        audio.play();
                    }
                    previousNotificationLength = res.data.length;
                    sessionStorage.setItem('previousNotificationLength', previousNotificationLength.toString());

                    console.log('Fetched notifications:', res.data);
                    // Update state or perform any other actions with the fetched notifications
                })
                .catch((error) => {
                    console.error('Error fetching notifications:', error);
                });
        });

        // Clean up event listeners when component unmounts
        return () => {
            socket.disconnect();
        };
    }, [user.user.uid]);

    const calculateTimeDifference = (createdAt) => {
        const now = new Date();
        const createdTime = new Date(createdAt);
        const timeDifference = now - createdTime;

        // Calculate time units
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const years = Math.floor(days / 365);

        if (seconds < 60) {
            return 'Just now';
        } else if (minutes < 60) {
            return `${minutes} min ago`;
        } else if (hours < 24) {
            return `${hours} hours ago`;
        } else if (days < 365) {
            return `${days} days ago`;
        } else {
            return `${years} years ago`;
        }
    };

    // Example usage
    const createdAt = '2022-01-01T00:00:00Z'; // Replace with the actual createdAt timestamp
    const timeAgo = calculateTimeDifference(createdAt);

    const [modalOpen, setModalOpen] = useState(false);
    const handleCallback = (data, status) => {
        if (!modalOpen) {
            // Toggle the modal state
            setModalStates(false);
        }
    };
    const [data, setdata] = useState();
    const Edite = (e) => {

        setdata(e);
        setModalStates(!modalStates)

    }

    const [selectedNotifications, setSelectedNotifications] = useState([]);

    const handleCheckboxChange = (notificationId) => {
        if (selectedNotifications.includes(notificationId)) {
            setSelectedNotifications(selectedNotifications.filter((id) => id !== notificationId));
        } else {
            setSelectedNotifications([...selectedNotifications, notificationId]);
        }
    };
    const deleteNotifications = () => {
        http.post('/notification/delete/', selectedNotifications).then((res) => {
        }).catch((e) => {
            console.log(e);
        })

        setcountss(countss + 1)
        // After deletion, you may want to clear the selectedNotifications array

    };
    useEffect(() => {
        // This effect runs only once when the component mounts
        setSelectedNotifications([]);
    }, []);
    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none">
                    <i className='bx bx-bell fs-22'></i>
                    <span
                        className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{NotificationLength ? NotificationLength : 0}<span
                            className="visually-hidden">unread messages</span></span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                </Col>
                                <div className="col-auto dropdown-tabs">
                                    <span className="badge bg-light-subtle text-body fs-13"> {NotificationLength ? NotificationLength : 0} New</span>
                                </div>
                            </Row>
                        </div>

                        <div className="px-2 pt-2">
                            <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
                                <NavItem >
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggleTab('1'); }}
                                    >
                                        unseen ({NotificationLength ? NotificationLength : 0})
                                    </NavLink>

                                </NavItem>
                                <NavItem style={{ marginLeft: "10px" }}>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggleTab('2'); }}
                                    >
                                        Seen ({ReadedNotificationLength ? ReadedNotificationLength : 0})
                                        {/* Seen ({NotificationLength ? NotificationLength : 0}) */}
                                    </NavLink>

                                </NavItem>
                            </Nav>
                        </div>

                    </div>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">


                                <div className="notification-item d-block dropdown-item position-relative">
                                    {Notification.length > 0 ? (
                                        Notification.map((item, index) => (
                                            <div key={index} className="d-flex" onClick={(e) => callbackfun(item.notification_id)}>
                                                <div className="avatar-xs me-3">
                                                    <span className="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                                                        <i className="bx bx-badge-check"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1">
                                                    {item.notification_type === 1 ? (
                                                        <Link to={`/application-list/1`} className="stretched-link">
                                                            <h6 className="mt-0 mb-2 lh-base">
                                                                <b>{item.notification_description}&nbsp;!!</b>
                                                            </h6>
                                                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                <span>
                                                                    <i className="mdi mdi-clock-outline"></i> {calculateTimeDifference(item.createdAt)}
                                                                </span>
                                                            </p>
                                                        </Link>
                                                    ) : item.notification_type === 5 ? (
                                                        <>
                                                            <Link to={`/`} className="stretched-link">
                                                                <h6 className="mt-0 mb-2 lh-base">
                                                                    <b>{item.notification_description}&nbsp;!!</b>
                                                                </h6>
                                                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                    <span>
                                                                        <i className="mdi mdi-clock-outline"></i> {calculateTimeDifference(item.createdAt)}
                                                                    </span>
                                                                </p>
                                                            </Link>
                                                        </>
                                                    ) : item.notification_type === 3 ? (
                                                        <>
                                                            <Link to={`/task-list/${item.assigned_data_id}`} className="stretched-link">
                                                                <h6 className="mt-0 mb-2 lh-base">
                                                                    <b>{item.notification_description}&nbsp;!!</b>
                                                                </h6>
                                                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                    <span>
                                                                        <i className="mdi mdi-clock-outline"></i> {calculateTimeDifference(item.createdAt)}
                                                                    </span>
                                                                </p>
                                                            </Link>
                                                        </>
                                                    ) : item.notification_type === 4 ? (
                                                        <>
                                                            <Link to={`/leave/${item.assigned_data_id}`} className="stretched-link">
                                                                <h6 className="mt-0 mb-2 lh-base">
                                                                    <b>{item.notification_description}&nbsp;!!</b>
                                                                </h6>
                                                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                    <span>
                                                                        <i className="mdi mdi-clock-outline"></i> {calculateTimeDifference(item.createdAt)}
                                                                    </span>
                                                                </p>
                                                            </Link>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <hr /> {/* Add <hr> tag here for separation */}
                                                </div>
                                                <div className="px-2 fs-15">
                                                    <div className="form-check notification-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            onClick={() => handleCheckboxChange(item.notification_id)}
                                                            checked={selectedNotifications.includes(item.notification_id)}
                                                            id={`all-notification-check${index + 1}`}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor={`all-notification-check${index + 1}`}
                                                        ></label>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </div>










                                <div className="my-3 text-center">
                                    <button
                                        type="button"
                                        className="btn btn-soft-danger waves-effect waves-light"
                                        onClick={deleteNotifications}
                                        disabled={selectedNotifications.length === 0}
                                    >
                                        Delete Notifications
                                    </button>
                                </div>
                            </SimpleBar>

                        </TabPane>

                        <TabPane tabId="2" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">


                                <div className="text-reset notification-item d-block dropdown-item position-relative">
                                    {
                                        ReadedNotification.length > 0 ?
                                            ReadedNotification.map((item, index) => (
                                                <div key={index} className="d-flex" >
                                                    <div className="avatar-xs me-3">
                                                        <span className="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                                                            <i className="bx bx-badge-check"></i>
                                                        </span>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        {item.notification_type === 1 ? (
                                                            <Link to={`/admission-list/`} className="stretched-link">
                                                                <h6 className="mt-0 mb-2 lh-base">
                                                                    <b>{item.notification_description}&nbsp;!!</b>
                                                                </h6>
                                                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                    <span>
                                                                        <i className="mdi mdi-clock-outline"></i> {calculateTimeDifference(item.createdAt)}
                                                                    </span>
                                                                </p>
                                                            </Link>
                                                        ) : item.notification_type === 2 ? (
                                                            <>
                                                                <Link to={`/leads-list/${item.assigned_data_id}`} className="stretched-link">
                                                                    <h6 className="mt-0 mb-2 lh-base">
                                                                        <b>{item.notification_description}&nbsp;!!</b>
                                                                    </h6>
                                                                    <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                        <span>
                                                                            <i className="mdi mdi-clock-outline"></i> {calculateTimeDifference(item.createdAt)}
                                                                        </span>
                                                                    </p>
                                                                </Link>
                                                            </>
                                                        ) : item.notification_type === 3 ? (
                                                            <>
                                                                <Link to={`/task-list/${item.assigned_data_id}`} className="stretched-link">
                                                                    <h6 className="mt-0 mb-2 lh-base">
                                                                        <b>{item.notification_description}&nbsp;!!</b>
                                                                    </h6>
                                                                    <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                        <span>
                                                                            <i className="mdi mdi-clock-outline"></i> {calculateTimeDifference(item.createdAt)}
                                                                        </span>
                                                                    </p>
                                                                </Link>
                                                            </>
                                                        ) : item.notification_type === 4 ? (
                                                            <>
                                                                <Link to={`/leave/${item.assigned_data_id}`} className="stretched-link">
                                                                    <h6 className="mt-0 mb-2 lh-base">
                                                                        <b>{item.notification_description}&nbsp;!!</b>
                                                                    </h6>
                                                                    <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                        <span>
                                                                            <i className="mdi mdi-clock-outline"></i> {calculateTimeDifference(item.createdAt)}
                                                                        </span>
                                                                    </p>
                                                                </Link>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <hr /> {/* Add <hr> tag here for separation */}
                                                    </div>
                                                    <div className="px-2 fs-15">
                                                        <div className="form-check notification-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value=""
                                                                onClick={() => handleCheckboxChange(item.notification_id)}
                                                                checked={selectedNotifications.includes(item.notification_id)}
                                                                id={`all-notification-check${index + 1}`}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`all-notification-check${index + 1}`}
                                                            ></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : (<></>)
                                    }

                                </div>









                                <div className="my-3 text-center">
                                    <button
                                        type="button"
                                        className="btn btn-soft-danger waves-effect waves-light"
                                        onClick={deleteNotifications}
                                        disabled={selectedNotifications.length === 0}
                                    >
                                        Delete Notifications
                                    </button>
                                </div>
                            </SimpleBar>
                        </TabPane>
                        <TabPane tabId="3" className="p-4">
                            <div className="w-25 w-sm-50 pt-3 mx-auto">
                                <img src={bell} className="img-fluid" alt="user-pic" />
                            </div>
                            <div className="text-center pb-5 mt-2">
                                <h6 className="fs-18 fw-semibold lh-base">Hey! You have no any notifications </h6>
                            </div>
                        </TabPane>
                    </TabContent>
                </DropdownMenu>
            </Dropdown>
            {modalStates === true ? (
                <NotificationView
                    modalStates={modalStates}
                    data={data}
                    callbackk={callbackfun}
                    setModalStates={() => {
                        setModalStates(false);
                    }}
                    checkchang={handleCallback}
                />
            ) : (
                ""
            )}
        </React.Fragment>
    );
};

export default NotificationDropdown;