import React, { useRef, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
  ButtonGroup,
  UncontrolledButtonDropdown,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Popover,
  PopoverBody,
  UncontrolledPopover,
  Input,
} from "reactstrap";
import Select from "react-select";

import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import ApplicationAdd from "./ApplicationAdd";
import ApplicationEdit from "./ApplicationEdit";
import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import { IMG_API_URL } from "../../helpers/url_helper";
import InfiniteScroll from "react-infinite-scroll-component";
import D_img from "../D_img";
import { useNavigate, useParams } from "react-router-dom";
import Applicationupdate from "./Applicationupdate";
import Flatpickr from "react-flatpickr";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const ApplicationList = () => {
  const [retailer, setRetailer] = useState([]);
  const getRetailer = async () => {
    http
      .get("/retailer/list")
      .then((res) => {
        setRetailer(res.data)
      })
      .catch((err) => {
        console.log(err);
      })
  }
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);
  const [UpdatemodalStates1, setUpdateModalStates1] = useState(false);
  const { http, user } = AuthUser();
  const { status, dash } = useParams();

  const [userList, setUserList] = useState([]);
  const currentYear = new Date().getFullYear();
  const startDate = new Date(currentYear, 0, 1).toISOString().slice(0, 10); // Start of current year
  const endDate = new Date(currentYear, 11, 31).toISOString().slice(0, 10); // End of current year

  const [filterData, setFilterData] = useState(() => {
    return dash ? {
      startDate: startDate,
      endDate: endDate,
      retailer_name: ""
    } : {
      startDate: new Date().toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10),
      retailer_name: ""
    };
  });

  // console.log(filterData);
  const getUserList = async () => {
    http
      .get("/user/list")
      .then((res) => {
        setUserList(res.data)
      })
      .catch((err) => {
        console.log(err);
        console.log("Something went wrong!");
      })
  }
  const [counts, Setcounts] = useState(1);
  const [ApplicationData, SetApplicationData] = useState([]);
  // console.log(ApplicationData);
  const getApplicationList = async () => {
    http
      .post(`/application/list`, filterData)
      .then(function (response) {
        // console.log(response.data);
        SetApplicationData(response.data)
        setOriginalEmployee(response.data)
      })
      .catch(function (error) {
        console.log(error);
        SetNoMore(false);
      });
  }
  useEffect(() => {
    getApplicationList();
  }, [filterData, counts]);

  const [Pages, SetPages] = useState(1);
  const [NoMore, SetNoMore] = useState(true);
  useEffect(() => {
    document.title = "Application | E-DOC";
    getUserList();
    // getApplicationList();
    getRetailer();
  }, [counts]);

  const fetchData = () => {
    Setcounts(counts + 1);
  };
  //   Delete Aleart
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, SetID] = useState();
  const onClickDelete = (data) => {
    SetID(data);
    setDeleteModal(true);
  };
  const handleDeleteOrder = (data) => {
    if (data._reactName == "onClick") {
      http
        .delete(`/city/delete/${ID}`)
        .then(function (response) {
          if (response.data.status == 0) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          Setcounts(counts + 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    SetApplicationData([]);
    SetPages(1);
    setDeleteModal(false);
  };


  //   end Alert
  const handleCallback = (data, status) => {
    SetApplicationData([]);
    SetPages(1);
    if (status == 1) {
      toast.success(data);
      Setcounts(counts + 1);
    } else {
      toast.warn(data);
      Setcounts(counts + 1);
    }
    setUpdateModalStates(false);
    setUpdateModalStates1(false);

    getApplicationList();
  };
  // Edit Data
  const [pdfUrl, setPdfUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const GetDocument = async (index) => {

    try {
      // Replace 'your_api_endpoint' with the actual endpoint URL
      const response = await http.get(`/application/track/download/${index}`, {
        responseType: 'blob', // Set the response type to blob
      });
      // console.log(response);
      if (response.headers['content-type'] === 'application/pdf') {
        // console.log("pdf");
        // PDF file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'document.pdf');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else if (response.headers['content-type'] === 'image/jpeg') {
        // JPG file
        // console.log("JPG");

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'document.jpg');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        // Unsupported file type
        setErrorMessage('Unsupported file type');
      }
    } catch (error) {
      console.error('Error downloading document:', error);
      setErrorMessage('Failed to download document');
    }
    // fetch(`http://localhost:8888/api/application/track/download/${index}`)
    //   .then(response => {
    //     if (!response.ok) {
    //       throw new Error('Network response was not ok');
    //     }
    //     return response.blob();
    //   })
    //   .then(blob => {
    //     // Create a local URL for the PDF blob
    //     const pdfBlobUrl = URL.createObjectURL(blob);
    //     setPdfUrl(pdfBlobUrl);

    //     // Trigger download when PDF is fetched
    //     downloadPdf(pdfBlobUrl);
    //   })
    //   .catch(error => {
    //     console.error('There was a problem with the fetch operation:', error);
    //   });

  };

  const tableRef = useRef(null);
  const downloadPDF = () => {
    const table = tableRef.current;
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    const pdf = new jsPDF();
    pdf.autoTable({ html: table });

    const blob = pdf.output("blob");
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "application_list.pdf";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadCSV = () => {
    const table = tableRef.current;
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    const csvContent = Array.from(table.querySelectorAll("tr"))
      .map((row) =>
        Array.from(row.children)
          .map((cell) => `"${cell.innerText.trim()}"`)
          .join(",")
      )
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "application_list.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [FindData, SetFind] = useState([]);
  const EditUpdate = (index) => {
    SetFind(ApplicationData.filter(item => item.application_id == index)[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };
  const [FindData1, SetFind1] = useState([]);
  const EditUpdate1 = (index) => {
    SetFind1(ApplicationData.filter(item => item.application_id == index)[0]);
    setUpdateModalStates1(!UpdatemodalStates1);
  };
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedLeadIndex, setSelectedLeadIndex] = useState(null);
  const togglePopover = (index) => {
    setPopoverOpen(!popoverOpen);
    setSelectedLeadIndex(index);
  };
  const [originalEmployee, setOriginalEmployee] = useState([]);
  const options = [
    { value: "0", label: "All Application" },
    { value: "1", label: "New Application" },
    { value: "2", label: "Process Application" },
    { value: "3", label: "Done Application" },
    { value: "4", label: "Objection Application" },
    { value: "6", label: "Reuploaded Application" },
    { value: "5", label: "Reject Application" },
  ];
  const navigate = useNavigate();
  const [defaultValue, setDefaultValue] = useState(options.find(option => option.value === status));

  useEffect(() => {
    setDefaultValue(options.find(option => option.value === status));
  }, [status]);
  return (
    <div className="page-content">
      <table
        ref={tableRef}
        id="table-to-pdf"
        style={{ display: "none" }}
        role="table"
        className="align-middle table-nowrap table table-hover"
      >
        <thead className="table-light text-muted text-uppercase">
          <tr>
            <th
              title="Toggle SortBy"
              style={{ cursor: "pointer" }}
            >
              #
            </th>
            <th
              title="Toggle SortBy"
              style={{ cursor: "pointer" }}
            >
              Date
            </th>
            <th
              title="Toggle SortBy"
              style={{ cursor: "pointer" }}
            >
              Service Name
            </th>
            <th
              title="Toggle SortBy"
              style={{ cursor: "pointer" }}
            >
              Retailer Name
            </th>
            <th
              title="Toggle SortBy"
              style={{ cursor: "pointer" }}
            >
              Customer Name
            </th>

            <th
              title="Toggle SortBy"
              style={{ cursor: "pointer" }}
            >
              Amount
            </th>
            <th
              title="Toggle SortBy"
              style={{ cursor: "pointer" }}
            >
              Status
            </th>
            {user.user.u_type == 3 ? "" :
              <th
                title="Toggle SortBy"
                style={{ cursor: "pointer" }}
              >
                Assigned to
              </th>
            }
          </tr>
        </thead>
        <tbody>
          {ApplicationData.filter(item => (
            status > 0
              ? (

                user.user.u_type == 2 && status > 1
                  ? (item.application_assigned_to == user.user.uid && item.application_status == status)
                  : user.user.u_type == 3 ? (item.retailer_id == user.user.uid && item.application_status == status)
                    : item.application_status == status
              )
              :
              user.user.u_type == 3 ? item.retailer_id == user.user.uid : user.user.u_type == 2 ? (item.application_status != 0 && user.user.uid == item.application_assigned_to) : item.application_status != 0






          )).reverse().map((item, index) => (
            <tr key={index}>
              <td>
                <a
                  className="fw-medium link-primary"
                  href="/apps-ecommerce-order-details"
                >
                  {index + 1}
                </a>
              </td>
              <td>{item.application_date && item.application_date.split("T")[0]}</td>
              <td>{item.service_name}</td>
              <td>{retailer.length > 0 && retailer.filter(data => data.uid == item.retailer_id)[0].name}</td>
              <td>{item.application_customer_name}</td>

              <td>{item.application_amount}</td>
              <td>{item.application_status == 1 ?
                <div>New</div>
                : item.application_status == 2 ?
                  <div>Process</div>
                  : item.application_status == 3 ?
                    <div>Done</div>
                    : item.application_status == 4 ?
                      <div>Objection</div>
                      : item.application_status == 5 ?
                        <div>Rejected</div> : item.application_status == 6 ?
                          <div>Reupdate</div>
                          : "Not Found"
              }</td>

              {
                user.user.u_type == 3 ? "" :
                  user.user.u_type === 1 ?
                    <td>
                      {(
                        <div>

                          {item.application_assigned_to ?
                            <div>
                              {userList.filter(check => check.uid === item.application_assigned_to).map((ans) => (
                                <>{ans.name}</>
                              ))}
                            </div>
                            : "Not Assign"}


                        </div>
                      )}
                    </td>
                    :
                    <td>
                      {item.application_assigned_to ?
                        <button className="btn btn-success">
                          {userList.filter(check => check.uid === item.application_assigned_to).map((ans) => (
                            <>{ans.name}</>
                          ))}
                        </button>
                        : (
                          "Not Assigned Yet"
                        )}
                    </td>
              }

            </tr>
          ))}
        </tbody>
      </table>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">Application  List</h5>
                  </div>


                  <div className="col-sm-auto " style={{ marginTop: "-3px" }}>
                    <div className="d-flex gap-1 flex-wrap">
                      <div >
                        <div className="fw-bold">Select Status</div>
                        <Select className="fw-bold" placeholder="Select Status"
                          value={defaultValue}
                          options={options}

                          onChange={(e) => {
                            navigate(`/application-list/${e.value}`);
                          }}
                        ></Select>
                      </div>
                      <div >
                        <div className="fw-bold">Start Date</div>
                        <Flatpickr

                          className="form-control fw-bold "
                          options={{
                            dateFormat: "Y-m-d",
                            defaultDate: filterData.startDate,
                          }}
                          name="payment_date"
                          placeholder="Start date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];

                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day}`;

                            setFilterData({
                              ...filterData,
                              startDate: formattedDate,
                            });
                          }}
                        />
                      </div>
                      <div className="" >
                        <div className="fw-bold">End Date</div>
                        <Flatpickr
                          className="form-control fw-bold"
                          options={{
                            dateFormat: "Y-m-d",
                            defaultDate: filterData.endDate,
                          }}
                          name="payment_date"
                          placeholder="End date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];

                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day}`;

                            setFilterData({
                              ...filterData,
                              endDate: formattedDate,
                            });
                          }}
                        />
                      </div>


                      {user.user.u_type !== 3 && (
                        <>
                          <div className="">
                            <div className="fw-bold">Retailer Name</div>
                            {/* <input className="form-control fw-bold" onChange={(e) => {
                              
                            }} placeholder="Retailer Name" /> */}
                            <Select
                              className="fw-bold"
                              placeholder={"Retailer Name"}
                              options={retailer.filter(item => item.u_type == 3).map((data, index) => ({
                                value: data.uid,
                                label: data.name,
                              }))}
                              onChange={(e) => {
                                setFilterData({
                                  ...filterData,
                                  retailer_name: e.value
                                })
                              }}
                            ></Select>
                          </div>

                        </>)
                      }
                      <div>
                        <div className="fw-bold fs-7 " style={{ marginTop: "4px" }}>   Customer Name </div>
                        <Input
                          style={{ marginTop: "-4px" }}
                          name="roll_no"
                          id="Department-field"
                          className="form-control fw-bold"
                          placeholder="  Customer Name "
                          type="text"
                          onChange={(e) => {
                            const searchQuery = e.target.value.trim().toLowerCase();
                            const filteredEmployee = searchQuery
                              ? originalEmployee.filter((item) =>
                                item.application_customer_name.toLowerCase().includes(searchQuery)
                              )
                              : originalEmployee;
                            SetApplicationData(filteredEmployee);
                          }}
                        />
                      </div>
                      <div className="mt-3">
                        <div className=" btn btn-info" onClick={downloadCSV}>CSV</div>
                      </div>
                      <div className="mt-3">
                        <div className=" btn btn-primary" onClick={downloadPDF}> PDF</div>
                      </div>
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">

                <div >
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  {/* <InfiniteScroll
                    dataLength={ApplicationData.length}
                    next={fetchData}
                    hasMore={NoMore}
                  > */}
                  <table
                    role="table"
                    className="align-middle table-nowrap table table-hover"
                  >
                    <thead className="table-light text-muted text-uppercase">
                      <tr>
                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          #
                        </th>
                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          Date
                        </th>
                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          Service Name
                        </th>
                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          Retailer Name
                        </th>
                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          Customer Name
                        </th>

                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          Amount
                        </th>
                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          Status
                        </th>
                        {user.user.u_type == 3 ? "" :
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Assigned to
                          </th>
                        }

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ApplicationData.filter(item => (
                        status > 0
                          ? (

                            user.user.u_type == 2 && status > 1
                              ? (item.application_assigned_to == user.user.uid && item.application_status == status)
                              : user.user.u_type == 3 ? (item.retailer_id == user.user.uid && item.application_status == status)
                                : item.application_status == status
                          )
                          :
                          user.user.u_type == 3 ? item.retailer_id == user.user.uid : user.user.u_type == 2 ? (item.application_status != 0 && user.user.uid == item.application_assigned_to) : item.application_status != 0






                      )).reverse().map((item, index) => (
                        <tr key={index}>
                          <td>
                            <a
                              className="fw-medium link-primary"
                              href="/apps-ecommerce-order-details"
                            >
                              {index + 1}
                            </a>
                          </td>
                          <td>{item.application_date && item.application_date.split("T")[0]}</td>
                          <td>{item.service_name}</td>
                          <td>{retailer.length > 0 && retailer.filter(data => data.uid == item.retailer_id)[0].name}</td>
                          <td>{item.application_customer_name}</td>

                          <td>{item.application_amount}</td>
                          <td>{item.application_status == 1 ?
                            <div className="badge bg-info">New</div>
                            : item.application_status == 2 ?
                              <div className="badge bg-primary">Process</div>
                              : item.application_status == 3 ?
                                <div className="badge bg-success">Done</div>
                                : item.application_status == 4 ?
                                  <div className="badge bg-secondary">Objection</div>
                                  : item.application_status == 5 ?
                                    <div className="badge bg-danger">Rejected</div> : item.application_status == 6 ?
                                      <div className="badge bg-warning">Reupdate</div>
                                      : "Not Found"
                          }</td>

                          {
                            user.user.u_type == 3 ? "" :
                              user.user.u_type === 1 ?
                                <td>
                                  {(
                                    <div>
                                      <Button
                                        color={"primary"}
                                        className="btn-label  right "
                                      >
                                        {" "}
                                        <i
                                          id={`PopoverDismissible${index}`}
                                          className=" ri-arrow-down-s-line label-icon align-middle  fs-16 ms-2"
                                          onClick={() => togglePopover(index)}
                                        ></i>{" "}
                                        {item.application_assigned_to ?
                                          <div>
                                            {userList.filter(check => check.uid === item.application_assigned_to).map((ans) => (
                                              <>{ans.name}</>
                                            ))}
                                          </div>
                                          : "Assign"}
                                      </Button>

                                      <UncontrolledPopover
                                        trigger="legacy"
                                        placement="bottom-start"
                                        target={`PopoverDismissible${index}`}
                                        isOpen={
                                          popoverOpen &&
                                          selectedLeadIndex === index
                                        }
                                        toggle={() => togglePopover(index)}
                                      >
                                        {userList.filter(check => check.uid !== item.application_assigned_to).map((e, i) => (
                                          <PopoverBody key={i}>
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                http
                                                  .put("/application/update", {
                                                    id: item.application_id,
                                                    application_assigned_to: e.uid,
                                                    application_status: 2
                                                  })
                                                  .then(function (response) {
                                                    Setcounts(counts + 1);
                                                    toast.success(
                                                      "Application Assigned successfully!"
                                                    );
                                                    togglePopover();
                                                  })
                                                  .catch(function (err) {
                                                    console.log(err);
                                                  });
                                              }}
                                            >
                                              {e.name}
                                            </div>
                                          </PopoverBody>
                                        ))}
                                      </UncontrolledPopover>
                                    </div>
                                  )}
                                </td>
                                :
                                <td>
                                  {item.application_assigned_to ?
                                    <button className="btn btn-success">
                                      {userList.filter(check => check.uid === item.application_assigned_to).map((ans) => (
                                        <>{ans.name}</>
                                      ))}
                                    </button>
                                    : (
                                      <div>
                                        <Button
                                          color={"primary"}
                                          className="btn-   "

                                          onClick={() => {

                                            http
                                              .put("/application/update", {
                                                id: item.application_id,
                                                application_assigned_to: user.user.uid,
                                                application_status: 2
                                              })
                                              .then(function (response) {
                                                Setcounts(counts + 1);
                                                toast.success(
                                                  "Application Assigned successfully!"
                                                );
                                                togglePopover();
                                              })
                                              .catch(function (err) {
                                                console.log(err);
                                              });
                                          }}
                                        >
                                          Assign Me
                                        </Button>
                                      </div>
                                    )}
                                </td>
                          }
                          <td>
                            <ul className="list-inline hstack gap-2 mb-0">
                              {user.user.u_type === 3 && item.application_status == 4 && (
                                <li className="list-inline-item edit">
                                  <button
                                    className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                    onClick={() => EditUpdate1(item.application_id)}
                                  >
                                    <i className="ri-pencil-fill fs-16" />
                                  </button>
                                </li>
                              )}
                              <li className="list-inline-item edit">
                                <button
                                  className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                  onClick={() => EditUpdate(item.application_id)}
                                >
                                  <i className="ri-eye-fill fs-16" />
                                </button>
                              </li>
                              {user.user.u_type === 3 && item.application_status == 3 && (
                                <li className="list-inline-item edit">
                                  <button
                                    className="text-success d-inline-block edit-item-btn border-0 bg-transparent"
                                    onClick={() => GetDocument(item.application_id)}
                                  >
                                    <i className="ri-download-fill fs-20 fw-bold" />
                                  </button>
                                </li>)}
                              {user.user.u_type === 2 && item.application_status == 3 && (
                                <li className="list-inline-item edit">
                                  <button
                                    className="text-success d-inline-block edit-item-btn border-0 bg-transparent"
                                    onClick={() => GetDocument(item.application_id)}
                                  >
                                    <i className="ri-download-fill fs-20 fw-bold" />
                                  </button>
                                </li>)}
                              {/* <li className="list-inline-item">
                                  {item.city_id != 1 ? (
                                    <button
                                      onClick={() =>
                                        onClickDelete(item.city_id)
                                      }
                                      className="text-danger d-inline-block remove-item-btn  border-0 bg-transparent"
                                    >
                                      <i className="ri-delete-bin-5-fill fs-16" />
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </li> */}
                            </ul>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* </InfiniteScroll> */}
                </div>


                {UpdatemodalStates === true ? (
                  <ApplicationEdit
                    modalStates={UpdatemodalStates}
                    setModalStates={() => {
                      setUpdateModalStates(false);
                    }}
                    getData={() => {
                      getApplicationList();
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {UpdatemodalStates1 === true ? (
                  <Applicationupdate
                    modalStates={UpdatemodalStates1}
                    setModalStates={() => {
                      setUpdateModalStates1(false);
                    }}
                    getData={() => {
                      getApplicationList();
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData1}
                  />
                ) : (
                  ""
                )}
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ApplicationList;
