import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
} from "reactstrap";
import "./sync.css";
//import images
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

//import Components
import NotificationDropdown from "../Components/Common/NotificationDropdown";
import ProfileDropdown from "../Components/Common/ProfileDropdown";
import LightDark from "../Components/Common/LightDark";

import { changeSidebarVisibility } from "../store/actions";
import { createSelector } from "reselect";
import AuthUser from "../helpers/Authuser";
import { useDispatch, useSelector } from "react-redux";
import { storePermissions } from "../store/permissions/PermissionsSlice";
import IconBxSync from "./Sync";
import { ToastContainer, toast } from "react-toastify";
import WalletDropDown from "../Components/Common/WalletDropDown";

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
  const [search, setSearch] = useState(false);
  const toogleSearch = () => {
    setSearch(!search);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const handleDoubleClick = () => {
    if (location.pathname !== '/login' && location.pathname !== '/dashboard') {
      navigate(-1); // Navigate back to the previous route
    }
    // navigate(-1); // Navigate back to the previous route
  };
  const dispatch = useDispatch();
  const sidebarVisibilityData = createSelector(
    (state) => state.Layout.sidebarVisibilitytype,
    (sidebarVisibilitytype) => sidebarVisibilitytype
  );

  const [greeting, setGreeting] = useState("");
  const { http, checkPermission, user } = AuthUser();

  useEffect(() => {
    // Get the current hour of the day
    const currentHour = new Date().getHours();

    // Function to determine the appropriate greeting based on the time
    function getGreeting() {
      if (currentHour >= 5 && currentHour < 12) {
        return "Good Morning";
      } else if (currentHour >= 12 && currentHour < 17) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    }

    // Set the greeting message
    setGreeting(getGreeting());
    // getUserPermissions();
  }, []);

  const getUserPermissions = async () => {
    const roleIDD = JSON.parse(sessionStorage.getItem("authUser")).user.role;
    const apiResponse = await http.get(`/get/permissions/${roleIDD}`);
    dispatch(storePermissions(apiResponse.data));
  };

  // Inside your component
  const sidebarVisibilitytype = useSelector(sidebarVisibilityData);

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;
    dispatch(changeSidebarVisibility("show"));
    if (windowSize > 767)
      document.querySelector(".hamburger-icon").classList.toggle("open");
    //For collapse horizontal menu
    if (document.documentElement.getAttribute("data-layout") === "horizontal") {
      document.body.classList.contains("menu")
        ? document.body.classList.remove("menu")
        : document.body.classList.add("menu");
    }
    //For collapse vertical and semibox menu
    if (
      sidebarVisibilitytype === "show" &&
      (document.documentElement.getAttribute("data-layout") === "vertical" ||
        document.documentElement.getAttribute("data-layout") === "semibox")
    ) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }
    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
  };

  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    setClicked(true);
    http
      .get("/sync")
      .then(function (response) {
        if (response.data.status == 1) {
          setClicked(false);
          toast.success(response.data.msg);
        } else {
          toast.error("Please Contact To Admin");
        }
      })
      .catch(function (error) {
        console.log(error);
        setClicked(false);
        toast.error("Please Contact To Admin");
      });
  };
  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="17" />
                  </span>
                </Link>
              </div>

              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
              {/* <Link className={`mt-3`} onClick={handleClick}>
                <IconBxSync className={clicked ? "clickedss" : ""}></IconBxSync>
              </Link> */}
              <div>
                <button
                  className="btn mt-3 btn-success "
                  onClick={handleDoubleClick}
                >
                  BACK
                </button>
              </div>

            </div>

            <div className="d-flex align-items-center">
              <Dropdown
                isOpen={search}
                toggle={toogleSearch}
                className="d-md-none topbar-head-dropdown header-item"
              >
                <DropdownToggle
                  type="button"
                  tag="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                >
                  <i className="bx bx-search fs-22"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                  <Form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </Form>
                </DropdownMenu>
              </Dropdown>
              <div
                className="text-center mt-2"
                style={{ marginRight: "120px" }}
              >
                <h5 className="text-primary">
                  Hello <span className="text-success"> {greeting}</span> 😊,
                  Welcome Back !
                </h5>
              </div>

              {/* Dark/Light Mode set */}
              {user.user.u_type === 3 &&
                <WalletDropDown />
              }

              <LightDark
                layoutMode={layoutModeType}
                onChangeLayoutMode={onChangeLayoutMode}
              />

              {/* NotificationDropdown */}
              <NotificationDropdown />

              {/* ProfileDropdown */}
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default Header;
