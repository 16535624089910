import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import ServicesAdd from "./ServicesAdd";
import ServicesEdit from "./ServicesEdit";
import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import { IMG_API_URL } from "../../helpers/url_helper";
import InfiniteScroll from "react-infinite-scroll-component";
import D_img from "../D_img";

const ServicesList = () => {
  const [modalStates, setModalStates] = useState(false);
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);
  const { http } = AuthUser();
  const [counts, Setcounts] = useState(1);
  const [ServicesData, SetServicesData] = useState({});
  // infinity
  const [Pages, SetPages] = useState(1);
  const [NoMore, SetNoMore] = useState(true);
  useEffect(() => {
    document.title = "Services | E-DOC";

    http
      .get(`/service/list`)
      .then(function (response) {
        SetServicesData(response.data);
        SetPages(Pages + 1);
        if (response.data.services.length === 0) {
          SetNoMore(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        SetNoMore(false);
      });
  }, [counts]);
  const fetchData = () => {
    Setcounts(counts + 1);
  };
  //   Delete Aleart
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, SetID] = useState();
  const onClickDelete = (data) => {
    SetID(data);
    setDeleteModal(true);
  };
  const handleDeleteOrder = (data) => {
    if (data._reactName == "onClick") {
      http
        .delete(`/service/delete/${ID}`)
        .then(function (response) {
          if (response.data.status == 1) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          Setcounts(counts + 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    SetServicesData([]);
    SetPages(1);
    setDeleteModal(false);
  };

  // shortcuts for opening add form
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "a" || event.altKey && event.key === "A") {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //   end Alert
  const handleCallback = (data, status) => {
    SetServicesData([]);
    SetPages(1);
    if (status == 1) {
      toast.success(data);
      Setcounts(counts + 1);
    } else {
      toast.warn(data);
      Setcounts(counts + 1);
    }
    setModalStates(false);
    setUpdateModalStates(false);
  };
  // Edit Data
  const [FindData, SetFind] = useState([]);
  const [DocumentData, setDocumentData] = useState([]);
  const EditUpdate = (index) => {
    let FindArray = ServicesData.services.filter((_, i) => i == index);
    let DocumentArray = ServicesData.master.filter((data, i) => data.service_id == ServicesData.services[index].service_id);
    SetFind(FindArray[0]);
    setDocumentData(DocumentArray);
    setUpdateModalStates(!UpdatemodalStates);
  };
  const [serachName, setSearchName] = useState("");
  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">Services  List</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <div>
                        <input className="form-control fw-bold" placeholder="Service Name" onChange={(e) => {
                          setSearchName(e.target.value);
                        }} />
                      </div>
                      <button
                        type="button"
                        className="btn fw-bold btn-success add-btn"
                        id="create-btn"
                        onClick={() => setModalStates(!modalStates)}
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Add
                        Services
                      </button>
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  {/* <InfiniteScroll
                    dataLength={ServicesData.services.length}
                    next={fetchData}
                    hasMore={NoMore}
                  > */}
                  <table
                    role="table"
                    className="align-middle table-nowrap table table-hover"
                  >
                    <thead className="table-light text-muted text-uppercase">
                      <tr>
                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          #
                        </th>
                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          Service Image
                        </th>
                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          Service Name
                        </th>
                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          Amount
                        </th>
                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          Document's
                        </th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ServicesData.services && ServicesData.services.filter(item => {
                        return (item.service_name).includes(serachName);
                      }).map((item, index) => (
                        <tr key={index}>
                          <td>
                            <a
                              className="fw-medium link-primary"
                              href="/apps-ecommerce-order-details"
                            >
                              {index + 1}
                            </a>
                          </td>
                          <td>
                            {item.service_image ?
                              <img src={`${IMG_API_URL + "/service/" + item.service_image}`} className="" height={"80px"} width={"80px"} />
                              :
                              <D_img />
                            }
                          </td>
                          <td>{item.service_name}</td>
                          <td>{item.service_amount}</td>
                          <td>
                            {ServicesData.master.length > 0 && ServicesData.master.filter(test => test.service_id == item.service_id).map((data, i) => (
                              <div key={i}>{data.document_name}</div>
                            ))}
                          </td>
                          <td>
                            <ul className="list-inline hstack gap-2 mb-0">
                              <li className="list-inline-item edit">
                                <button
                                  className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                  onClick={() => EditUpdate(index)}
                                >
                                  <i className="ri-pencil-fill fs-16" />
                                </button>
                              </li>
                              <li className="list-inline-item">
                                {item.service_id != 1 ? (
                                  <button
                                    onClick={() =>
                                      onClickDelete(item.service_id)
                                    }
                                    className="text-danger d-inline-block remove-item-btn  border-0 bg-transparent"
                                  >
                                    <i className="ri-delete-bin-5-fill fs-16" />
                                  </button>
                                ) : (
                                  ""
                                )}
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* </InfiniteScroll> */}
                </div>

                {modalStates === true ? (
                  <ServicesAdd
                    modalStates={modalStates}
                    setModalStates={() => {
                      setModalStates(false);
                    }}
                    checkchang={handleCallback}
                  />
                ) : (
                  ""
                )}
                {UpdatemodalStates === true ? (
                  <ServicesEdit
                    modalStates={UpdatemodalStates}
                    setModalStates={() => {
                      setUpdateModalStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                    document_data={DocumentData}
                  />
                ) : (
                  ""
                )}
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div >
  );
};

export default ServicesList;
