import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useRef } from "react";
import Select from "react-select";
import AuthUser from "../../../helpers/Authuser";
import { IMG_API_URL } from "../../../helpers/url_helper";

const UserView = (props) => {
  const [modal, setModal] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const { https, http } = AuthUser();

  const [EmployeeAdhar, SetEmployeeAdhar] = useState("");
  const [EmployeeProfile, SetEmployeeProfile] = useState("");
  const [EmployeePan, SetEmployeePan] = useState("");
  const [EmployeeQrCode, SetEmployeeQrCode] = useState("");
  const [employeeData, setemployeeData] = useState(props.edit_data);
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    // getRolesList();
    toggle();
  }, [props.modalStates]);

  // GETTING ROLES LIST
  const getRolesList = async () => {
    try {
      const apiResponse = await https.get("/role/list");
      setRolesList(apiResponse.data);
    } catch (error) {
      console.log(error);
    }
  };
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");
  const handleEmployee = (e) => {
    setCheckStatus({});
    setMsg("");
    setemployeeData({ ...employeeData, [e.target.name]: e.target.value });
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <Modal id="showModal" size="xl" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          View Employee
        </ModalHeader>
        <span className="tablelist-form">
          <ModalBody>
            <Card className="border card-border-success p-3 shadow-lg">
              <div className="mb-3">
                <Row>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Employee Name <span className="text-danger">*</span>{" "}
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      disabled
                      checkNameStatus={checkNameStatus}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Employee Name"
                      type="text"
                      name="name"
                      value={employeeData.name}
                    />
                  </Col>

                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      Mobile
                    </Label>
                    <Input
                      disabled
                      value={employeeData.mobile_no}
                      id="mobile_no"
                      name="mobile_no"
                      className="form-control fw-bold"
                      placeholder="Mobile Number"
                      type="number"
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div> Mobile number</div>
                    </Label>
                    <Input
                      disabled
                      value={employeeData.emergency_contact}
                      name="emergency_contact"
                      id="emergency_contact"
                      onChange={(e) => handleEmployee(e)}
                      className="form-control fw-bold"
                      placeholder="Emergency Mobile Number"
                      type="text"
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div> Email</div>
                    </Label>
                    <Input
                      disabled
                      value={employeeData.email}
                      name="email"
                      id="email"
                      onChange={(e) => handleEmployee(e)}
                      className="form-control fw-bold"
                      placeholder="Email address"
                      type="text"
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div> Password</div>
                    </Label>
                    <Input
                      disabled
                      value={employeeData.password}
                      name="password"
                      id="password"
                      onChange={(e) => handleEmployee(e)}
                      className="form-control fw-bold"
                      placeholder="Password"
                      type="password"
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div> Salary</div>
                    </Label>
                    <Input
                      disabled
                      value={employeeData.salary}
                      name="salary"
                      id="salary"
                      onChange={(e) => handleEmployee(e)}
                      className="form-control fw-bold"
                      placeholder="Salary"
                      type="text"
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div> Date of Joining</div>
                    </Label>
                    <Input
                      disabled
                      value={employeeData.date_of_joining}
                      name="date_of_joining"
                      id="date_of_joining"
                      onChange={(e) => handleEmployee(e)}
                      className="form-control fw-bold"
                      placeholder="Salary"
                      type="date"
                    />
                  </Col>

                  <Col lg={3}>
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      Shift
                    </Label>
                    <Input
                      disabled
                      value={employeeData && employeeData.shift_name}
                      name="shift_id"
                      id="shift_id"
                      className="fw-bold"
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      Role

                    </Label>
                    <Input
                      disabled
                      value={employeeData && employeeData.role_name}
                      name="user_role_id"
                      id="user_role_id"
                      className="fw-bold"
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Last Experience
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      disabled
                      value={employeeData.last_experience}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder=" Last Experience"
                      type="text"
                      name="last_experience"
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Last Working Company
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      disabled
                      value={employeeData.last_working_company}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Last Working Company"
                      type="text"
                      name="last_working_company"
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Last Company Salary
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      disabled
                      value={employeeData.last_company_salary}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Last Company Salary"
                      type="text"
                      name="last_company_salary"
                    />
                  </Col>
                  <Col lg={4}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Aadhar Number
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      disabled
                      value={employeeData.aadhar_no}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Adhar Number"
                      type="text"
                      name="Adhar Number"
                    />
                  </Col>
                  <Col lg={4}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Pan No
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      disabled
                      value={employeeData.pan_no}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Pan Number"
                      type="text"
                      name="pan_no"
                    />
                  </Col>
                  <Col lg={4}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        User Upi
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      disabled
                      value={employeeData.user_upi}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Upi"
                      type="text"
                      name="user_upi"
                    />
                  </Col>
                  <Col lg={12}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      Address
                    </Label>
                    <textarea
                      disabled
                      value={employeeData.address}
                      name="address"
                      id="address"
                      placeholder="Employee address"
                      className="form-control fw-bold"
                    ></textarea>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Adhar Image</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">

                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {employeeData.aadhar_photo ? (
                                <img
                                  src={`${IMG_API_URL}` + employeeData.aadhar_photo}
                                  alt={`${IMG_API_URL}` + employeeData.aadhar_photo}
                                  id="product-img"
                                  height={"100px"}
                                  width={"100px"}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Pan Image</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">

                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {employeeData.pan_photo ? (
                                <img
                                  src={`${IMG_API_URL}` + employeeData.pan_photo}
                                  alt={`${IMG_API_URL}` + employeeData.pan_photo}
                                  id="product-img"
                                  height={"100px"}
                                  width={"100px"}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Profile</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">

                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {employeeData.profile_photo ? (
                                <img
                                  src={`${IMG_API_URL}` + employeeData.profile_photo}
                                  alt={`${IMG_API_URL}` + employeeData.profile_photo}
                                  id="product-img"
                                  height={"100px"}
                                  width={"100px"}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Bank Passbook</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">

                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {employeeData.bank_passbook_photo ? (
                                <img
                                  src={`${IMG_API_URL}` + employeeData.bank_passbook_photo}
                                  alt={`${IMG_API_URL}` + employeeData.bank_passbook_photo}
                                  id="product-img"
                                  height={"100px"}
                                  width={"100px"}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </ModalBody>
          <div className="modal-footer">
            <button
              name="close"
              id="close"
              type="button"
              className="btn btn-danger"
              onClick={() => Close()}
            >
              <i className="ri-close-line me-1 align-middle" />
              Close
            </button>
          </div>
        </span>
      </Modal>
    </div>
  );
};

export default UserView;
