import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
  CardHeader,
  CardBody,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import { IMG_API_URL } from "../../helpers/url_helper";
import Select from "react-select";
import D_img from "../D_img";
import JSZip from "jszip";
const ApplicationEdit = (props) => {
  const { http, https, user } = AuthUser();
  const [tempValid, settempValid] = useState(0);
  const [modal, setModal] = useState(false);
  const [ApplicationData, setApplicationData] = useState(props.edit_data);
  // console.log(ApplicationData);
  const [EmployeeDocument, SetEmployeeDocument] = useState("");
  const [applicationTrack, setApplciationTrack] = useState({
    at_application_id: props.edit_data.application_id,
    at_application_status: "",
    at_description: "",
    at_document: "",
    at_user: user.user.uid
  })
  const [documentDetails, setDocumentsDetails] = useState([]);
  const [documentDetails1, setDocumentsDetails1] = useState([]);
  // console.log(documentDetails1);
  const getDocumentDetails = async () => {
    http
      .get(`/application/show/${props.edit_data.application_id}`)
      .then((res) => {
        setDocumentsDetails(res.data[0].documents);
        setDocumentsDetails1(res.data);
      })
      .catch((err) => {
        console.log(err);
        console.log("Something Went Wrong!");
      })
  }
  const [count, setCount] = useState(0);
  const handleCallback = (data, status) => {
    if (status === 1) {
      toast.success(data);
      setCount(count + 1);
    } else {
      toast.warn(data);
      setCount(count + 1);
    }
  };

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    getDocumentDetails();
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);



  const trackOptions = [
    { value: "1", label: "New" },
    { value: "2", label: "Process" },
    { value: "3", label: "Done" },
    { value: "4", label: "Objection" },
    { value: "6", label: "Reupdate" },
    { value: "5", label: "Reject" },
  ];

  const optionValue = [];

  if (ApplicationData.application_status === 1) {
    optionValue.push(
      // { value: 1, label: "New" },
      { value: 2, label: "Process" },
      { value: 3, label: "Done" },
      { value: 4, label: "Objection" },
      { value: 5, label: "Reject" }, { value: 6, label: "Reupdate" }
    );
  } else if (ApplicationData.application_status === 2) {
    optionValue.push(
      { value: 3, label: "Done" },
      { value: 4, label: "Objection" },
      { value: 5, label: "Reject" }, { value: 6, label: "Reupdate" }
    );
  } else if (ApplicationData.application_status === 3) {
    optionValue.push(
      // { value: 3, label: "Done" }
    );
  } else if (ApplicationData.application_status === 4) {
    optionValue.push(
      { value: 1, label: "New" },
      { value: 2, label: "Process" },
      { value: 3, label: "Done" },
      { value: 4, label: "Objection" },
      { value: 5, label: "Reject" }, { value: 6, label: "Reupdate" }
    );
  } else if (ApplicationData.application_status === 5) {
    optionValue.push(
      { value: 1, label: "New" },
      { value: 2, label: "Process" },
      { value: 3, label: "Done" },
      { value: 4, label: "Objection" },
      { value: 5, label: "Reject" }
    );
  } else if (ApplicationData.application_status === 6) {
    optionValue.push(
      { value: 1, label: "New" },
      { value: 2, label: "Process" },
      { value: 3, label: "Done" },
      { value: 4, label: "Objection" },
      { value: 5, label: "Reject" },
      { value: 6, label: "Reupdate" }
    );
  } else {
    // Handle other cases, or leave it empty
  }

  const updateStatus = async () => {
    if (applicationTrack.at_application_status == "") {
      settempValid(1);
    } else if (applicationTrack.at_application_status == 4 && applicationTrack.at_description == "") {
      settempValid(2);
    }
    else if (applicationTrack.at_application_status == 5 && applicationTrack.at_description == "") {
      settempValid(2);
    }
    else if (applicationTrack.at_application_status == 3 && applicationTrack.at_document == "") {
      settempValid(3);
    } else {
      https
        .post("/application/track/store", applicationTrack)
        .then((res) => {
          toast.success(res.data.message);
          props.getData();

        })
        .catch((err) => {
          console.log(err);
          console.log("Something went wrong!");
        })
      setModal(false);
      props.setModalStates();
    }
  }

  const downloadFile = (url, filename) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = filename;

        // Simulate a click on the link to trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
      })
      .catch(error => console.error('Error downloading file:', error));
  };

  const downloadAllImages = () => {
    // Create a new instance of JSZip
    const zip = new JSZip();

    // Promise to track completion of all image downloads
    const promises = [];

    // Add each image to the zip archive
    documentDetails.forEach(data => {
      const url = `${IMG_API_URL}/uploads/${data.document_file}`;
      const fileName = data.document_file;

      // Fetch each image and add it to the zip archive
      const promise = fetch(url)
        .then(response => response.blob())
        .then(blob => zip.file(fileName, blob))
        .catch(error => console.error(`Error downloading ${fileName}:`, error));

      promises.push(promise);
    });

    // Once all promises are resolved, generate and download the zip file
    Promise.all(promises).then(() => {
      zip.generateAsync({ type: "blob" }).then(content => {
        // Trigger download of the zip file
        const zipFile = new Blob([content]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(zipFile);
        link.download = `${ApplicationData.application_id}_${ApplicationData.application_customer_name}_${ApplicationData.service_name}.zip`;
        link.click();
      });
    });
  };
  // Download Document
  const [errorMessage, setErrorMessage] = useState('');
  const GetDocument = async (index) => {

    try {
      // Replace 'your_api_endpoint' with the actual endpoint URL
      const response = await http.get(`/application/track/download/${index}`, {
        responseType: 'blob', // Set the response type to blob
      });
      // console.log(response);
      if (response.headers['content-type'] === 'application/pdf') {
        // console.log("pdf");
        // PDF file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'document.pdf');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else if (response.headers['content-type'] === 'image/jpeg') {
        // JPG file
        // console.log("JPG");

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'document.jpg');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        // Unsupported file type
        setErrorMessage('Unsupported file type');
      }
    } catch (error) {
      console.error('Error downloading document:', error);
      setErrorMessage('Failed to download document');
    }


  };


  const [objection, setObjection] = useState({});
  // console.log(objection);
  const getObjection = async () => {
    http
      .get(`/application/track/show/${props.edit_data.application_id}`)
      .then((res) => {
        if (res.data.length > 0) {
          setObjection(res.data[res.data.length - 1])
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }
  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    getObjection();
    document.title = "View Application | E-DOC";
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <div>
      <Modal
        className="modal-xl"
        id="fullscreeexampleModal"
        isOpen={modal}
        size={"lg"}
        toggle={toggle}
        centered
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          View Application
        </ModalHeader>
        <span className="tablelist-form">
          <ModalBody
            style={{ maxHeight: "80vh", overflowY: "auto" }}
          >
            <Card className="border card-border-success p-3 shadow-lg">
              <Row className="gutters-sm   mx-2">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex flex-column align-items-center text-center">
                        {ApplicationData.profile_photo ?
                          <img src={IMG_API_URL + "/user/" + ApplicationData.profile_photo} alt="User Image" className="rounded-circle" width={150} height={150} />
                          : <img
                            src="https://i.pinimg.com/originals/0c/3b/3a/0c3b3adb1a7530892e55ef36d3be6cb8.png"
                            alt="Retailer Image"
                            className="rounded-circle" width={150} height={150} />}
                        <p className="mt-2 fw-bold" > {ApplicationData.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="card mt-3 p-3">
                    <center>
                      <p className="font-weight-bold fw-bold">Activation Status</p>
                    </center>
                    {user.user.u_type != 3 ?
                      <div>
                        <div className="text-danger fw-bold">{tempValid == 1 ? "Select the status to change!" : ""}</div>
                        <Select
                          options={optionValue}
                          placeholder={ApplicationData.application_status === 1 ? "New"
                            : ApplicationData.application_status === 2 ? "Process"
                              : ApplicationData.application_status === 3 ? "Done"
                                : ApplicationData.application_status === 4 ? "Objection"
                                  : ApplicationData.application_status === 5 ? "Reject" : ApplicationData.application_status === 6 ? "Reupdate"
                                    : ""}
                          className="fw-bold"
                          onChange={(e) => {
                            settempValid(0);
                            setApplciationTrack({
                              ...applicationTrack,
                              at_application_status: e.value
                            })
                          }}
                        ></Select>
                        <div className="text-danger fw-bold  mt-2">{tempValid == 2 ? "Description can't be empty!" : ""}</div>
                        <textarea name="application_remark" onChange={(e) => {
                          settempValid(0);
                          setApplciationTrack({
                            ...applicationTrack,
                            at_description: e.target.value
                          })
                        }} placeholder={objection && objection.at_application_status == ApplicationData.application_status && objection.at_description} cols={30} rows={5} className="fw-bold form-control" />
                        {applicationTrack && applicationTrack.at_application_status === 3 &&

                          <div className="mb-4 mt-3">
                            <h5 className="fs-15 mb-1">Document Upload </h5>
                            <div className="text-danger fw-bold">{tempValid == 3 ? "Please Upload Document!" : ""}</div>
                            <div className="text-center">
                              <div className="position-relative d-inline-block">
                                <div className="position-absolute top-100 start-100 translate-middle">
                                  <label
                                    htmlFor="empoyeeProfile"
                                    className="mb-0"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="right"
                                    title=""
                                    data-bs-original-title="Select Image"
                                  >
                                    <div className="avatar-xs">
                                      <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                        <i className="ri-image-fill"></i>
                                      </div>
                                    </div>
                                  </label>
                                  <input
                                    className="form-control d-none"
                                    id="empoyeeProfile"
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg, application/pdf"
                                    onChange={(e) => {
                                      setApplciationTrack({
                                        ...applicationTrack,
                                        at_document: e.target.files[0],
                                      });
                                      SetEmployeeDocument(e.target.files[0]);
                                    }}
                                  />
                                </div>'    '
                                <div className="avatar-lg">
                                  <div className="avatar-title bg-light rounded">
                                    {EmployeeDocument && (
                                      <>
                                        {EmployeeDocument.type.startsWith("image/") ? (
                                          <img
                                            src={URL.createObjectURL(EmployeeDocument)}
                                            id="product-img"
                                            alt="product-img"
                                            width={"100px"}
                                            height={"100px"}
                                          />
                                        ) : (
                                          <embed
                                            src={URL.createObjectURL(EmployeeDocument)}
                                            type="application/pdf"
                                            width="100px"
                                            height="100px"
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        }
                        <div className="text-center">
                          <button className="btn-primary btn mt-3" onClick={() => {
                            updateStatus();
                          }}>Update Status</button>
                        </div>
                      </div>
                      :
                      <div>
                        <h4 className="text-center fw-bold">
                          {ApplicationData.application_status === 1 ? "New" : ApplicationData.application_status === 2 ? "Process" : ApplicationData.application_status === 3 ? "Done" : ApplicationData.application_status === 4 ? "Objection" : ApplicationData.application_status === 5 ? "Reject" : ApplicationData.application_status === 6 ? "Reupdate" : ""}
                        </h4>
                        <h5>

                          {objection.at_application_status == ApplicationData.application_status && objection.at_description}
                        </h5>
                        {user.user.u_type === 3 && ApplicationData.application_status == 3 && (

                          <button
                            className="bg-primary badge fs-5 mb-2"
                            onClick={() => GetDocument(ApplicationData.application_id)}
                          >
                            Download Document
                            {/* <i className="ri-download-fill fs-20 fw-bold" /> */}
                          </button>
                        )}

                      </div>
                    }
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-4">
                          <h6 className="mb-0 fw-bold">Service Name</h6>
                        </div>
                        <div className="col-sm-8 text-secondary fw-bold">
                          {ApplicationData.service_name}
                        </div>
                      </div>

                      <hr />
                      <div className="row">
                        <div className="col-sm-4">
                          <h6 className="mb-0 fw-bold">Customer Name</h6>
                        </div>
                        <div className="col-sm-8 text-secondary fw-bold">
                          {ApplicationData.application_customer_name}
                        </div>
                      </div>
                      <hr />
                      {/* <div className="row">
                        <div className="col-sm-4">
                          <h6 className="mb-0">Customer Email</h6>
                        </div>
                        <div className="col-sm-8 text-secondary">
                          {ApplicationData.application_customer_email}
                        </div>
                      </div>
                      <hr /> */}
                      <div className="row">
                        <div className="col-sm-4">
                          <h6 className="mb-0 fw-bold">Customer Phone</h6>
                        </div>
                        <div className="col-sm-8 text-secondary fw-bold">
                          {ApplicationData.application_customer_mobile}
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-4">
                          <h6 className="mb-0 fw-bold">Application Status : </h6>
                        </div>
                        <div className="col-sm-8 text-secondary fw-bold">
                          {ApplicationData.application_status == 1 ?
                            <div className="badge bg-info">New</div>
                            : ApplicationData.application_status == 2 ?
                              <div className="badge bg-primary">Process</div>
                              : ApplicationData.application_status == 3 ?
                                <div className="badge bg-success">Done</div>
                                : ApplicationData.application_status == 4 ?
                                  <div className="badge bg-secondary">Objection</div>
                                  : ApplicationData.application_status == 5 ?
                                    <div className="badge bg-danger">Rejected</div>
                                    : "Not Found"
                          }
                        </div>
                      </div>
                      <hr />

                      <div className="row">
                        <div className="col-sm-4">
                          <h6 className="mb-0 fw-bold">Remark</h6>
                        </div>
                        <div className="col-sm-8 text-secondary fw-bold">
                          {ApplicationData.application_remark}
                        </div>
                      </div>
                      <hr />
                      {user.user.u_type === 2 || user.user.u_type === 1 && ApplicationData.application_status == 3 && (

                        <button
                          className="bg-primary badge fs-5"
                          onClick={() => GetDocument(ApplicationData.application_id)}
                        >
                          Download Document
                          {/* <i className="ri-download-fill fs-20 fw-bold" /> */}
                        </button>
                      )}
                      <div className="col-md-12">


                        <div className="fw-bold">Application Track</div>
                        {trackOptions.map((e, i) => (
                          <div
                            key={i}
                            className=" mt-3 d-flex justify-content-between "
                          >
                            <div className="d-flex">
                              <i
                                className={` text-${e.value == 1
                                  ? "info"
                                  : e.value == 2
                                    ? "primary"
                                    : e.value == 3
                                      ? "warning"
                                      : e.value == 4
                                        ? "success"
                                        : e.value == 5
                                          ? "danger"
                                          : ""
                                  } ri-checkbox-blank-circle-fill fs-16 ms-2 mx-2`}
                              ></i>
                              <div className="mt-1">{e.label}</div>
                            </div>
                            <div>
                              {ApplicationData.application_status >= e.value && e.value <= 2 ? (
                                <i className="text-white bg-success p-1 ri-checkbox-circle-fill fs-16 ms-2"></i>
                              ) :
                                ApplicationData.application_status == 6 && e.value == 4 ?
                                  (
                                    <i className="text-white bg-success p-1 ri-checkbox-circle-fill fs-16 ms-2"></i>
                                  ) :
                                  ApplicationData.application_status == e.value
                                    ?
                                    (
                                      <i className="text-white bg-success p-1 ri-checkbox-circle-fill fs-16 ms-2"></i>
                                    )
                                    : (
                                      <i className="text-white bg-danger p-1 ri-close-circle-fill fs-16 ms-2"></i>
                                    )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card mb-3">
                    <div className="card-body">


                      {documentDetails.map((data, index) => (
                        <>
                          <div className="row">
                            <div className="col-sm-4">
                              <h6 className="mb-0 fw-bold">{data.document_name} :</h6>
                            </div>
                            <div className="col-sm-8 text-secondary">

                              <p>
                                <button onClick={() => {
                                  downloadFile(`${IMG_API_URL}/uploads/${data.document_file}`, data.document_file)
                                }} className="bg-primary badge" >Download</button>
                              </p>
                            </div>
                          </div>
                          <hr />
                        </>
                      ))}
                    </div>
                    <div className="card-footer">
                      <button onClick={() => {
                        downloadAllImages();
                      }} className="bg-primary badge">Download All Images</button>
                    </div>
                  </div>
                </div>
              </Row>
            </Card>

          </ModalBody>
          <div className="modal-footer">
            <button
              name="close"
              id="close"
              type="button"
              className="btn btn-danger"
              onClick={() => Close()}
            >
              <i className="ri-close-line me-1 align-middle" />
              Close
            </button>
          </div>
        </span>
      </Modal>


    </div>
  );
};

export default ApplicationEdit;
