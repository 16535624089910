import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthUser from "../helpers/Authuser";

const Navdata = () => {
  const history = useNavigate();
  const { user, permission } = AuthUser();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isReports, setIsReports] = useState(false);

  const [isCategories, setIsCategories] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [isTax, setIsTax] = useState(false);
  const [isUnits, setIsUnits] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [isemployee, setIsemployee] = useState(false);
  const [isstudent, setIsstudent] = useState(false);
  const [isproduct, setIsproduct] = useState(false);
  const [issupplier, setIssupplier] = useState(false);
  const [issale, setIssale] = useState(false);
  const [isicard, setIsicard] = useState(false);
  const [isApplication, setIsApplication] = useState(false);
  const [isRetailer, setIsRetailer] = useState(false);
  const [isWallet, setIsWallet] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }
  const permissionsList = useSelector(
    (state) => state.permissionsSlice.permissionsList
  );
  const [isAdmin, setIsAdmin] = useState(false);

  // CHECKING IF THE LOGGED IN USER IS AN ADMIN OR NOT

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Print Barcodes") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Categories") {
      setIsCategories(false);
    }
    if (iscurrentState !== "units") {
      setIsUnits(false);
    }
    if (iscurrentState !== "setting") {
      setIsSettings(false);
    }
    if (iscurrentState !== "reports") {
      setIsReports(false);
    }
    if (iscurrentState !== "Tax") {
      setIsTax(false);
    }
    if (iscurrentState !== "Profile") {
      setIsProfile(false);
    }
    if (iscurrentState !== "employee") {
      setIsemployee(false);
    }
    if (iscurrentState !== "student") {
      setIsstudent(false);
    }
    if (iscurrentState !== "product") {
      setIsproduct(false);
    }

    if (iscurrentState !== "supplier") {
      setIssupplier(false);
    }
    if (iscurrentState !== "Sale") {
      setIssale(false);
    }
    if (iscurrentState !== "idcard") {
      setIsicard(false);
    }
  }, [history, iscurrentState, isicard, isDashboard, isCategories, isTax, isemployee, isstudent, isproduct, issupplier, issale, isApplication, isRetailer, isWallet]);

  const menuItemsList = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "DASHBOARD",
      label: "Dashboard",
      icon: "ri-dashboard-line",
      link: user.user.u_type === 1 ? "/dashboard" : user.user.u_type === 2 ? "/user-dashboard" : "/retailer-dashboard",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      // id: "APPLICATION_FORM",
      id: "APPLICATION",
      label: "Application Form",
      icon: " ri-account-pin-box-fill",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsApplication(!isApplication);
        setIscurrentState("application");
        updateIconSidebar(e);
      },


      stateVariables: isApplication,
      subItems: [
        {
          id: "roles_list",
          label: "All Application",
          link: "/application-list/0",
          parentId: "employee",
        },
        {
          id: "roles_list",
          label: "New Application",
          link: "/application-list/1",
          parentId: "employee",
        },

        {
          id: "roles_list",
          label: "Process Application",
          link: "/application-list/2",
          parentId: "employee",
        },
        {
          id: "roles_list",
          label: "Done Application",
          link: "/application-list/3",
          parentId: "employee",
        },
        {
          id: "roles_list",
          label: "Objection Application",
          link: "/application-list/4",
          parentId: "employee",
        },
        {
          id: "roles_list",
          label: "Reupdate Application",
          link: "/application-list/6",
          parentId: "employee",
        },
        {
          id: "roles_list",
          label: "Reject Application",
          link: "/application-list/5",
          parentId: "employee",
        },

      ],
    },
    user.user.u_type !== 3 &&
    {

      // id: "Wallet",
      id: "WALLET",
      label: "Wallet",
      icon: " ri-account-pin-box-fill",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsWallet(!isWallet);
        setIscurrentState("employee");
        updateIconSidebar(e);
      },


      stateVariables: isWallet,
      subItems: [
        ...(permission.find(permission => permission.permission_category === "WALLET" && permission.permission_path === "1") ? [{
          id: "RECHARGE",
          label: "Self Recharge",
          link: "/recharge-create",
          parentId: "WALLET",
        }] : []),
        ...(permission.find(permission => permission.permission_category === "WALLET" && permission.permission_path === "2") ? [{
          id: "HISTORY",
          label: "History",
          link: "/recharge-list",
          parentId: "WALLET",
        }] : []),
        ...(permission.find(permission => permission.permission_category === "WALLET" && permission.permission_path === "2") ? [{
          id: "TRANSACTION",
          label: "Transaction",
          link: "/transactions-list",
          parentId: "WALLET",
        }] : []),
      ],
    },
    user.user.u_type !== 3 &&
    {
      id: "RETAILER",
      label: "Manage Retailer",
      icon: " ri-account-pin-box-fill",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsRetailer(!isRetailer);
        setIscurrentState("employee");
        updateIconSidebar(e);
      },


      stateVariables: isRetailer,
      subItems: [
        {
          id: "roles_list",
          label: "All Retailer",
          link: "/retailer-list/0",
          parentId: "employee",
        },
        {
          id: "roles_list",
          label: "Approved",
          link: "/retailer-list/1",
          parentId: "employee",
        },
        {
          id: "roles_list",
          label: "Reject",
          link: "/retailer-list/2",
          parentId: "employee",
        },

      ],
    },
    user.user.u_type !== 3 &&
    {
      id: "EMPLOYEE",
      label: "Employee",
      icon: " ri-account-pin-box-fill",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsemployee(!isemployee);
        setIscurrentState("employee");
        updateIconSidebar(e);
      },


      stateVariables: isemployee,
      subItems: [
        {
          id: "users_list",
          label: "All Employee",
          link: "/users-list/0",
          parentId: "employee",
        },
        {
          id: "users_list",
          label: "Active Employee",
          link: "/users-list/1",
          parentId: "employee",
        },
        {
          id: "users_list",
          label: "Deactive Employee",
          link: "/users-list/2",
          parentId: "employee",
        },
        ...(permission.find(permission => permission.permission_category === "ROLES") ? [{
          id: "roles_list",
          label: "Roles",
          link: "/roles-list",
          parentId: "EMPLOYEE",
        }] : []),
      ],
    },

    user.user.u_type !== 3 &&
    {
      id: "SETTINGS",
      label: "Settings",
      icon: "bx bx-cog",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsSettings(!isSettings);
        setIscurrentState("setting");
        updateIconSidebar(e);
      },
      stateVariables: isSettings,
      subItems: [
        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "1") ? [{
          id: "services_list",
          label: "Services",
          link: "/services-list",
          parentId: "SETTINGS",
        }] : []),
        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "2") ? [{
          id: "document_list",
          label: "Document's",
          link: "/document-list",
          parentId: "SETTINGS",
        }] : []),
        {
          id: "customer",
          label: "All customer",
          link: "/customer",
          parentId: "customer",
        },

      ],
    },

  ];
  const filteredMenuItems = menuItemsList.filter(menuItem => {
    // Check if any permission in the permission array matches the menuItem.id
    return permission.some(dataItem => menuItem.id === dataItem.permission_category);
  });


  return <React.Fragment>{filteredMenuItems.filter((item) => item !== null)}</React.Fragment>;
};
export default Navdata;
