import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";

const RequiredDocumentAdd = (props) => {
  const { http } = AuthUser();
  const [modal, setModal] = useState(false);
  const [RequiredDocument, SetRequiredDocument] = useState({
    document_name: "",
    document_description: "",
    document_status: 1
  });
  // console.log(RequiredDocument);


  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");
  const SubmitData = () => {
    if (RequiredDocument == "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("RequiredDocument cannot be empty!");
    } else {


      http
        .post("/document/store", RequiredDocument)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };


  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }

      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        Close();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Create RequiredDocument
        </ModalHeader>
        <span className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="cityname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          RequiredDocument Name<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg}</div>
                      </Label>
                      <CustomInput
                        checkNameStatus={checkNameStatus}
                        name="city_name"
                        id="city-field"
                        className="form-control fw-bold"
                        placeholder="RequiredDocument Name"
                        type="text"
                        value={RequiredDocument.document_name}
                        onChange={(e) => {
                          SetRequiredDocument({
                            ...RequiredDocument,
                            document_name: e.target.value
                          })
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="cityname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          RequiredDocument Description
                        </div>
                        <div style={{ color: "red" }}>{msg}</div>
                      </Label>
                      <Input
                        checkNameStatus={checkNameStatus}
                        name="city_name"
                        id="city-field"
                        className="form-control fw-bold"
                        placeholder="RequiredDocument Name"
                        type="text"
                        value={RequiredDocument.document_description}
                        onChange={(e) => {
                          SetRequiredDocument({
                            ...RequiredDocument,
                            document_description: e.target.value
                          })
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="cityname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Required   <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizemd"
                              onChange={(e) => {
                                const value = e.target.checked ? 2 : 1;
                                SetRequiredDocument({
                                  ...RequiredDocument,
                                  document_status: value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </Label>
                      {/* <div>
                        <input
                          type="radio"
                          className="btn-check shadow"
                          value={1}
                          name="documentType"
                          onClick={(e) => {
                            SetRequiredDocument({
                              ...RequiredDocument,
                              document_status: parseInt(e.target.value),
                            });
                          }}
                          id="documentType-notcompulsary"
                          autoComplete="off"

                          checked={RequiredDocument.document_status === 1 ? true : false}
                        />
                        <label
                          className="btn btn-outline-success mx-2"
                          htmlFor="documentType-notcompulsary"
                        >
                          Not Compulsary
                        </label>
                        <input
                          type="radio"
                          className="btn-check shadow"
                          value={2}
                          name="documentType"
                          onClick={(e) => {
                            SetRequiredDocument({
                              ...RequiredDocument,
                              document_status: parseInt(e.target.value),
                            });
                          }}
                          id="documentType-Compulsary"
                          autoComplete="off"
                          checked={RequiredDocument.document_status === 2 ? true : false}
                        />
                        <label
                          className="btn btn-outline-success mx-2"
                          htmlFor="documentType-Compulsary"
                        >
                          Compulsary
                        </label>

                      </div> */}



                    </div>
                  </Col>

                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <button
                ref={submitButtonRef}
                name="close"
                id="close"
                type="submit"
                className="btn btn-primary"
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                Save
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>

            </div>
          </Form>
        </span>
      </Modal>
    </div>
  );
};

export default RequiredDocumentAdd;
