import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  CardText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";


import AuthUser from "../../helpers/Authuser";

import { Link, useNavigate } from "react-router-dom";

import CountUp from "react-countup";
import FeatherIcon from "feather-icons-react";
import { IMG_API_URL } from "../../helpers/url_helper";
import D_img from "../D_img";
import ApplicationEdit from "../ServiceApplication/ApplicationEdit";
import RecentOrders from "./RecentOrders";
const DashboardEcommerce = () => {
  const [status, setstatus] = useState(0);

  document.title = "Dashboard | EDOC";
  const [counts, Setcounts] = useState(1);
  const [RetailerData, SetRetailerData] = useState([]);
  const { user, http, token } = AuthUser();
  const navigate = useNavigate();
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);
  const [NoMore, SetNoMore] = useState(true);
  const handleCallback = (data, status) => {
    SetApplicationData([]);

    setUpdateModalStates(false);

  };
  const [FindData, SetFind] = useState([]);
  const EditUpdate = (index) => {
    SetFind(ApplicationData.filter(item => item.application_id == index)[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };
  const [Employee, setEmployee] = useState([]);
  // console.log(Employee);
  useEffect(() => {
    http
      .get(`/user/list`)
      .then(function (response) {
        if (response.data.length > 0) {
          setEmployee(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        SetNoMore(false);
      });
  }, [counts]);
  useEffect(() => {
    document.title = "Retailer | E-DOC";

    http
      .get(`/retailer/list`)
      .then(function (response) {
        SetRetailerData(response.data);

      })
      .catch(function (error) {
        console.log(error);
        // SetNoMore(false);
      });
  }, [counts]);
  useEffect(() => {
    if (token === null) {
      navigate("/login");
    }
  }, []);
  const currentYear = new Date().getFullYear();
  const startDate = new Date(currentYear, 0, 1).toISOString().slice(0, 10); // Start of current year
  const endDate = new Date(currentYear, 11, 31).toISOString().slice(0, 10); // End of current year

  const [filterData, setFilterData] = useState({
    startDate: startDate,
    endDate: endDate,
    retailer_name: ""
  })
  // console.log(filterData);
  const [ApplicationData, SetApplicationData] = useState([]);
  // console.log("yucjhadc",ApplicationData);
  const getApplicationList = async () => {
    http
      .post(`/application/list`, filterData)
      .then(function (response) {
        // console.log(response.data);
        SetApplicationData(response.data)
      })
      .catch(function (error) {
        console.log(error);
        // SetNoMore(false);
      });
  }
  useEffect(() => {
    getApplicationList();
  }, [filterData, counts]);



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          <Row>
            <Col md={4}>
              <Card className="card-animate">
                <CardBody>
                  <div className="d-flex justify-content-between" onClick={() =>
                    navigate("/users-list/0")
                  }>
                    <div>
                      <p className="fw-medium text-muted mb-0">Total Employee</p>
                      <h2 className="mt-4 ff-secondary fw-semibold">
                        <span className="counter-value">
                          <CountUp
                            start={0}
                            end={Employee.length}
                            // decimals={1}
                            duration={4}
                          />
                        </span></h2>
                      {/* <p className="mb-0 text-muted"><span className="badge bg-light text-success mb-0">
                                        <i className="ri-arrow-up-line align-middle"></i> 16.24 %
                                    </span> vs. previous month</p> */}
                    </div>
                    <div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-primary rounded-circle fs-2">
                          <FeatherIcon
                            icon="users"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="card-animate">
                <CardBody>
                  <div className="d-flex justify-content-between" onClick={() =>
                    navigate("/retailer-list/0")
                  }>
                    <div>
                      <p className="fw-medium text-muted mb-0">Total Retailers</p>
                      <h2 className="mt-4 ff-secondary fw-semibold">
                        <span className="counter-value">
                          <CountUp
                            start={0}
                            end={RetailerData.length}
                            // decimals={1}
                            duration={4}
                          />
                        </span></h2>
                      {/* <p className="mb-0 text-muted"><span className="badge bg-light text-success mb-0">
                                        <i className="ri-arrow-up-line align-middle"></i> 16.24 %
                                    </span> vs. previous month</p> */}
                    </div>
                    <div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-primary rounded-circle fs-2">
                          <FeatherIcon
                            icon="users"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={4}>
              <Card className="card-animate">
                <CardBody>
                  <div className="d-flex justify-content-between" onClick={() =>
                    navigate("/application-list/0/1")
                  }>
                    <div>
                      <p className="fw-medium text-muted mb-0">Total Applications</p>
                      <h2 className="mt-4 ff-secondary fw-semibold">
                        <span className="counter-value" data-target="97.66">
                          <CountUp
                            start={0}
                            end={ApplicationData.length}
                            // decimals={2}
                            duration={4}
                          />
                        </span></h2>
                      {/* <p className="mb-0 text-muted"><span className="badge bg-light text-danger mb-0">
                                        <i className="ri-arrow-down-line align-middle"></i> 3.96 %
                                    </span> vs. previous month</p> */}
                    </div>
                    <div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-danger rounded-circle fs-2">
                          <FeatherIcon
                            icon="activity"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>

            {UpdatemodalStates === true ? (
              <ApplicationEdit
                modalStates={UpdatemodalStates}
                setModalStates={() => {
                  setUpdateModalStates(false);
                }}
                getData={() => {
                  getApplicationList();
                }}
                checkchang={handleCallback}
                edit_data={FindData}
              />
            ) : (
              ""
            )}
            <Col lg={12}>
              <div>
                <div className="row">
                  <h4 className="text-black fw-bold p-4">Applications</h4>
                  <div className="col-xl-12">
                    <div className=" crm-widget">
                      <div className=" p-0">
                        <div className="row row-cols-xxl-6 row-cols-md-3 row-cols-1 g-0">
                          <div className="col card   ">
                            <div className="py-4 px-3">
                              <h5 className="text-black fw-bold">
                                New
                                <i className="ri-arrow-up-circle-line text-info fs-18 float-end align-middle" />
                              </h5>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0" >
                                  <i className="ri-pulse-line display-6 text-info " />
                                </div>
                                <div
                                  className="flex-grow-1 ms-3"
                                  onClick={() =>
                                    navigate("/application-list/1/1")
                                  }
                                >
                                  <h2 className="mb-0">
                                    <CardText className="text-black fw-bold" style={{ cursor: "pointer" }}>
                                      Total:   <CountUp
                                        start={0}
                                        end={ApplicationData.filter((item) => item.application_status === 1).length}
                                        // decimals={1}
                                        duration={4}
                                      />

                                      {/* {ApplicationData.filter((item)=>item.application_status===1).length} */}
                                    </CardText>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col card  ">
                            <div className="py-4 px-3">
                              <h5 className="text-black fw-bold">
                                Progress
                                <i className="ri-arrow-up-circle-line text-primary fs-18 float-end align-middle" />
                              </h5>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <i className="ri-pulse-line display-6  text-primary" />
                                </div>
                                <div
                                  className="flex-grow-1 ms-3"
                                  onClick={() =>
                                    navigate("/application-list/2/1")
                                  }
                                >
                                  <h2 className="mb-0">
                                    <CardText className="text-black fw-bold" style={{ cursor: "pointer" }}>
                                      Total:
                                      <CountUp
                                        start={0}
                                        end={ApplicationData.filter((item) => item.application_status === 2).length}
                                        // decimals={0}
                                        duration={4}
                                      />
                                    </CardText>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col card  ">
                            <div className="py-4 px-3">
                              <h5 className="text-black fw-bold">
                                Done
                                <i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle" />
                              </h5>

                              <div className="d-flex align-items-center">
                                <div
                                  className="flex-shrink-0"

                                >
                                  <i className="ri-pulse-line display-6 text-success " />
                                </div>
                                <div className="flex-grow-1 ms-3" onClick={() =>
                                  navigate("/application-list/3/1")
                                }>
                                  <h2 className="mb-0">
                                    <CardText className="text-black fw-bold" style={{ cursor: "pointer" }}>
                                      Total: <CountUp
                                        start={0}
                                        end={ApplicationData.filter((item) => item.application_status === 3).length}
                                        // decimals={1}
                                        duration={4}
                                      />

                                    </CardText>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col card  ">
                            <div className="py-4 px-3">
                              <h5 className="text-black fw-bold"
                              >
                                Objection                                <i className="ri-arrow-up-circle-line text-warning fs-18 float-end align-middle" />
                              </h5>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <i className="ri-pulse-line display-6 text-warning" />
                                </div>
                                <div className="flex-grow-1 ms-3" onClick={() =>
                                  navigate("/application-list/4/1")
                                }>
                                  <h2 className="mb-0">
                                    <CardText className="text-black fw-bold" style={{ cursor: "pointer" }}>
                                      Total: <CountUp
                                        start={0}
                                        end={ApplicationData.filter((item) => item.application_status === 4).length}
                                        // decimals={1}
                                        duration={4}
                                      />
                                    </CardText>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col card  ">
                            <div className="py-4 px-3">
                              <h5 className="text-black fw-bold">
                                Reupdate
                                <i className="ri-arrow-up-circle-line text-orange fs-18 float-end align-middle" />
                              </h5>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <i className="ri-pulse-line display-6 text-orange" />
                                </div>
                                <div className="flex-grow-1 ms-3"
                                  onClick={() =>
                                    navigate("/application-list/6/1")
                                  }>
                                  <h2 className="mb-0">
                                    <CardText className="text-black fw-bold" style={{ cursor: "pointer" }}>
                                      Total:
                                      <CountUp
                                        start={0}
                                        end={ApplicationData.filter((item) => item.application_status === 6).length}
                                        // decimals={1}
                                        duration={4}
                                      />

                                    </CardText>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col card  ">
                            <div className="py-4 px-3">
                              <h5 className="text-black fw-bold">
                                Reject
                                <i className="ri-arrow-up-circle-line text-danger fs-18 float-end align-middle" />
                              </h5>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <i className="ri-pulse-line display-6 text-danger" />
                                </div>
                                <div className="flex-grow-1 ms-3"
                                  onClick={() =>
                                    navigate("/application-list/5/1")
                                  }>
                                  <h2 className="mb-0">
                                    <CardText className="text-black fw-bold" style={{ cursor: "pointer" }}>
                                      Total:  <CountUp
                                        start={0}
                                        end={ApplicationData.filter((item) => item.application_status === 5).length}
                                        // decimals={1}
                                        duration={4}
                                      />
                                    </CardText>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>

            <Col xxl={8}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1 fw-bold"> Recent Applications</h4>
                  <div className="flex-shrink-0">

                    <div className="col-sm-auto">
                      <div className="d-flex gap-1 flex-wrap">
                        <Link to="/application-list/0/1" className="btn btn-soft-info btn-sm shadow-none">View All</Link>
                      </div>
                    </div>
                  </div>
                </CardHeader>

                <div className="card-body">

                  <div className="table-responsive table-card">
                    <table
                      role="table"
                      className="align-middle table-nowrap table table-hover"
                    >
                      <thead className="table-light text-muted text-uppercase">
                        <tr>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            #
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Date
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Service Name
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Retailer Name
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Customer Name
                          </th>

                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Amount
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Status
                          </th>



                        </tr>
                      </thead>
                      <tbody>
                        {ApplicationData.filter(item => (
                          status > 0
                            ? (

                              user.user.u_type == 2 && status > 1
                                ? (item.application_assigned_to == user.user.uid && item.application_status == status)
                                : user.user.u_type == 3 ? (item.retailer_id == user.user.uid && item.application_status == status)
                                  : item.application_status == status
                            )
                            :
                            user.user.u_type == 3 ? item.retailer_id == user.user.uid : user.user.u_type == 2 ? (item.application_status != 0 && user.user.uid == item.application_assigned_to) : item.application_status != 0






                        )).reverse().slice(0, 10).map((item, index) => (
                          <tr key={index} onClick={() => EditUpdate(item.application_id)}>
                            <td>
                              <a
                                className="fw-medium link-primary"
                                href="/apps-ecommerce-order-details"
                              >
                                {index + 1}
                              </a>
                            </td>
                            <td>{item.application_date && item.application_date.split("T")[0]}</td>
                            <td>{item.service_name}</td>
                            <td>{item.name}</td>
                            <td>{item.application_customer_name}</td>

                            <td>{item.application_amount}</td>
                            <td>{item.application_status == 1 ?
                              <div className="badge bg-info">New</div>
                              : item.application_status == 2 ?
                                <div className="badge bg-primary">Process</div>
                                : item.application_status == 3 ?
                                  <div className="badge bg-success">Done</div>
                                  : item.application_status == 4 ?
                                    <div className="badge bg-secondary">Objection</div>
                                    : item.application_status == 5 ?
                                      <div className="badge bg-danger">Rejected</div> : item.application_status == 6 ?
                                        <div className="badge bg-warning">Reupdate</div>
                                        : "Not Found"
                            }</td>


                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card>
            </Col>
            <RecentOrders />

            <Col xxl={6}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1 fw-bold">All Retailer</h4>
                  <div className="flex-shrink-0">
                    <div className="col-sm-auto">
                      <div className="d-flex gap-1 flex-wrap">
                        <Link to="/retailer-list/0" className="btn btn-soft-info btn-sm shadow-none">View All</Link>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <div className="card-body">
                  <div className="table-responsive">
                    <Table className="align-middle table-nowrap table-hover">
                      <thead className="table-light text-muted text-uppercase">
                        <tr>
                          <th title="Toggle SortBy" style={{ cursor: "pointer", width: "2%" }}>#</th>
                          <th title="Toggle SortBy" style={{ cursor: "pointer", width: "8%" }}>Image</th>
                          <th title="Toggle SortBy" style={{ cursor: "pointer", width: "15%" }}>Name</th>
                          <th title="Toggle SortBy" style={{ cursor: "pointer", width: "15%" }}>Shop Name</th>
                          <th title="Toggle SortBy" style={{ cursor: "pointer", width: "10%" }}>Phone Number</th>
                          <th title="Toggle SortBy" style={{ cursor: "pointer", width: "10%" }}>EMAIL</th>
                          <th title="Toggle SortBy" style={{ cursor: "pointer", width: "10%" }}>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {RetailerData.slice(0, 10).reverse().map((item, index) => (
                          <tr key={index}>
                            <td>
                              <a className="fw-medium link-primary" href="/apps-ecommerce-order-details">
                                {index + 1}
                              </a>
                            </td>
                            <td>
                              {item.profile_photo !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + "/user/" + item.profile_photo}
                                  alt="Profile"
                                  height="50"
                                  width="50"
                                  style={{ objectFit: 'cover', borderRadius: '50%' }}
                                />
                              ) : (
                                <div style={{ height: '50px', width: '50px', backgroundColor: '#ccc', borderRadius: '50%' }}></div>
                              )}
                            </td>
                            <td>{item.name}</td>
                            <td>{item.business_name}</td>
                            <td>{item.mobile_no}</td>
                            <td>{item.email}</td>

                            <td>{item.status === 1 ? <div className="badge bg-success" >
                              Approved
                            </div> : <div className="badge bg-danger">Rejected</div>}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xxl={6}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1 fw-bold">All Employee</h4>
                  <div className="flex-shrink-0">
                    <div className="col-sm-auto">
                      <div className="d-flex gap-1 flex-wrap">
                        <Link to="/users-list/0" className="btn btn-soft-info btn-sm shadow-none">View All</Link>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <div className="card-body">
                  <div className="table-responsive">
                    <Table className="align-middle table-nowrap table-hover">
                      <thead className="table-light text-muted text-uppercase">
                        <tr>
                          <th title="Toggle SortBy" style={{ cursor: "pointer", width: "2%" }}>#</th>
                          <th title="Toggle SortBy" style={{ cursor: "pointer", width: "8%" }}>Image</th>
                          <th title="Toggle SortBy" style={{ cursor: "pointer", width: "15%" }}>Name</th>
                          <th title="Toggle SortBy" style={{ cursor: "pointer", width: "10%" }}>EMAIL</th>
                          <th title="Toggle SortBy" style={{ cursor: "pointer", width: "10%" }}>MOBILE NUMBER</th>
                          <th title="Toggle SortBy" style={{ cursor: "pointer", width: "10%" }}>ROLE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Employee.slice(0, 10).reverse().map((item, index) => (
                          <tr key={index}>
                            <td>
                              <a className="fw-medium link-primary" href="/apps-ecommerce-order-details">
                                {index + 1}
                              </a>
                            </td>
                            <td>
                              {item.profile_photo !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + item.profile_photo}
                                  alt="Profile"
                                  height="50"
                                  width="50"
                                  style={{ objectFit: 'cover', borderRadius: '50%' }}
                                />
                              ) : (
                                <div style={{ height: '50px', width: '50px', backgroundColor: '#ccc', borderRadius: '50%' }}></div>
                              )}
                            </td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.mobile_no}</td>
                            <td>{item.role_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
