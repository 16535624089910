import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Form,
  Button,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";


const CustomerAdd = (props) => {
  // On form submit getCustomerData imported
  const { http } = AuthUser();


  const [counts, Setcounts] = useState(1);

  const handleCallback = (data) => {
    Setcounts(counts + 1);

    toast.success(data);

  };
  const [validStatus, setValidStatus] = useState(0);

  const [CustomersData, setCustomersData] = useState({
    customer_name: "",
    customer_whatsapp_no: "",
    customer_alternative_no: "",
    customer_email: "",

    customer_designation: "",
    customer_address: "",
    customer_compnay_name: "",
  });
  // console.log(CustomersData);
  const getCustomerData = (e) => {
    setCustomersData({ ...CustomersData, [e.target.name]: e.target.value });
    if (CustomersData.customer_name != "") {
      setCheckStatus({});
      setMsg("");
    }
  };

  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");

  const OnSubmited = () => {

    if (CustomersData.customer_name == "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Customer name can not be empty!");
    }
    else if (CustomersData.customer_whatsapp_no == "") {
      setValidStatus(1);
    }
    else {
      const mainCustomerArray = {
        allCustomers: CustomersData,
      };
      http
        .post("/customer/store", mainCustomerArray.allCustomers)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
          setModal(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const [modal, setModal] = useState(false);

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <Modal id="showModal" size="xl" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Create Customer
        </ModalHeader>
        <div className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              OnSubmited();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success  p-3 shadow-lg">
                <div className="mb-3 row">
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      <div>
                        Full Name<span style={{ color: "red" }}> *</span>
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <CustomInput
                      checkNameStatus={checkNameStatus}
                      onChange={getCustomerData}
                      name="customer_name"
                      id="customer_name"
                      className="form-control fw-bold"
                      placeholder="Full Name"
                      type="text"
                    />
                  </div>

                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      <div> WhatsApp Number<span className="text-danger fw-bold"> *</span></div>
                      <div className="text-danger fw-bold">{validStatus === 1 ? "Whatsapp cannot be empty !" : ""}</div>
                    </Label>

                    <Input
                      type="number"
                      value={CustomersData ? CustomersData.customer_whatsapp_no : ""}
                      onChange={(e) => {
                        setValidStatus(0);
                        // Remove non-numeric characters
                        const input = e.target.value.replace(/\D/g, "");

                        // Apply validation for 10-digit number
                        if (input.length <= 10) {
                          setCustomersData({
                            ...CustomersData,
                            customer_whatsapp_no: input,
                          });
                        }

                        // Clear input on backspace
                        if (
                          e.nativeEvent.inputType === "deleteContentBackward" &&
                          input.length === 0
                        ) {
                          setCustomersData({
                            ...CustomersData,
                            customer_whatsapp_no: "",
                          });
                        }
                      }}
                      className="fw-bold"
                      placeholder="WhatsApp Number"
                    />
                  </div>
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold"
                    >
                      Alternative Mobile Number
                    </Label>
                    <Input
                      type="text"
                      value={CustomersData ? CustomersData.customer_alternative_no : ""}
                      onChange={(e) => {
                        // Remove non-numeric characters
                        const input = e.target.value.replace(/\D/g, "");

                        // Apply validation for 10-digit number
                        if (input.length <= 10) {
                          setCustomersData({
                            ...CustomersData,
                            customer_alternative_no: input,
                          });
                        }

                        // Clear input on backspace
                        if (
                          e.nativeEvent.inputType === "deleteContentBackward" &&
                          input.length === 0
                        ) {
                          setCustomersData({
                            ...CustomersData,
                            customer_alternative_no: "",
                          });
                        }
                      }}
                      className="fw-bold"
                      placeholder="Alternative Mobile Number"
                    />
                  </div>
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold"
                    >
                      Email
                    </Label>
                    <Input
                      onChange={getCustomerData}
                      name="customer_email"
                      id="customer_email"
                      className="form-control fw-bold"
                      placeholder="Email"
                      type="text"
                    />
                  </div>


                  <div className="col-12">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-3 fw-bold"
                    >
                      Address
                    </Label>
                    <textarea
                      onChange={getCustomerData}
                      rows={3}
                      placeholder="Enter address"
                      className="form-control fw-bold"
                      name="customer_address"
                      id="customer_address"
                    />
                  </div>
                </div>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <div className="hstack gap-2 justify-content-end">

                <button
                  type="submit"
                  name="sumbit"
                  id="submit"
                  className="btn btn-primary"
                  // onClick={() => OnSubmited()}
                  ref={submitButtonRef}
                >
                  <i className="ri-save-3-line align-bottom me-1"></i>
                  Save
                </button>

                <Button
                  name="close"
                  id="close"
                  type="button"
                  className="btn btn-danger"
                  onClick={() => Close()}
                >
                  <i className="ri-close-line me-1 align-middle" />
                  Close
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>

    </div>
  );
};

export default CustomerAdd;
