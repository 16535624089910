import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const RetailerAdd = (props) => {
  const { https, http, user } = AuthUser();
  const [modal, setModal] = useState(false);




  const [retailer, setRetailer] = useState({
    retailer_name: "",
    retailer_image: "",
    retailer_phone_number: "",
    retailer_email: "",
    retailer_password: "",
    retailer_address: "",
  });



  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [tempValid, settempValid] = useState(0);
  const SubmitData = () => {
    if (retailer.retailer_name === "") {
      settempValid(1);
    } else if (retailer.retailer_phone_number === "") {
      settempValid(2);
    }
    else if (retailer.retailer_email === "") {
      settempValid(3);
    }
    else if (retailer.retailer_password === "") {
      settempValid(4);
    }
    else {
      https
        .post("/retailer/store", retailer)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        Close();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);




  return (
    <div>
      <Modal
        className="modal-xl"
        id="fullscreeexampleModal"
        isOpen={modal}
        size={"xl"}
        toggle={toggle}
        centered
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Create Retailer
        </ModalHeader>
        <span className="tablelist-form">
          <Form


          >
            <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>
                  <Col lg={4} className="mb-3">
                    <Label
                      htmlFor="CandidateName-field"
                      className="form-label fw-bold d-flex justify-content-between"
                    >
                      <div>
                        Retailer Name
                        <span style={{ color: "red" }}> *</span>
                      </div>
                      <div style={{ color: "red" }}>
                        {tempValid === 1 ? "Name cannot be empty!" : ""}
                      </div>
                    </Label>
                    <Input className="fw-bold" placeholder="Retailer Name" onChange={(e) => {
                      settempValid(0);
                      setRetailer({
                        ...retailer,
                        retailer_name: e.target.value
                      })
                    }} />
                  </Col>
                  <Col lg={4} className="mb-3">
                    <Label className="fw-bold">
                      <div>
                        Phone Number
                        <span style={{ color: "red" }}> *</span>
                      </div>
                      <div style={{ color: "red" }}>
                        {tempValid === 2 ? "Number cannot be empty!" : ""}
                      </div>
                    </Label>
                    <Input type="text" className="fw-bold" placeholder="Mobile Number" value={
                      retailer ? retailer.retailer_phone_number : ""
                    } onChange={

                      (e) => {
                        // Remove non-numeric characters
                        const input = e.target.value.replace(/\D/g, '');

                        // Apply validation for 10-digit number
                        if (input.length <= 10) {
                          setRetailer({
                            ...retailer,
                            retailer_phone_number: input,
                          });
                        }

                        // Clear input on backspace
                        if (e.nativeEvent.inputType === 'deleteContentBackward' && input.length === 0) {
                          setRetailer({
                            ...retailer,
                            retailer_phone_number: '',
                          });
                        }
                      }
                    } />
                  </Col>
                  <Col lg={4} className="mb-3">
                    <Label className="fw-bold">
                      Shop Name
                    </Label>
                    <Input className="fw-bold" placeholder="Shope Name" onChange={(e) => {
                      setRetailer({
                        ...retailer,
                        retailer_shop: e.target.value
                      })
                    }} />
                  </Col>
                  <Col lg={4} className="mb-3">
                    <Label className="fw-bold">
                      <div>  Email
                        <span style={{ color: "red" }}> *</span>
                      </div>
                      <div style={{ color: "red" }}>
                        {tempValid === 3 ? "Email cannot be empty!" : ""}
                      </div>
                    </Label>
                    <Input className="fw-bold" placeholder="Email" onChange={(e) => {
                      setRetailer({
                        ...retailer,
                        retailer_email: e.target.value
                      })
                    }} />
                  </Col>
                  <Col lg={4} className="mb-3">
                    <Label className="fw-bold"><div>
                      Password
                      <span style={{ color: "red" }}> *</span>
                    </div>
                      <div style={{ color: "red" }}>
                        {tempValid === 4 ? "Password cannot be empty!" : ""}
                      </div>
                    </Label>
                    <Input className="fw-bold" type="password" placeholder="Password" onChange={(e) => {
                      setRetailer({
                        ...retailer,
                        retailer_password: e.target.value
                      })
                    }} />
                  </Col>
                  <Col
                    lg={4} className="mb-3">
                    <div className="mb-3">
                      <Label
                        htmlFor="CandidateName-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>Retailer Image / Shop Logo</div>
                      </Label>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="taskImage"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Image"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-image-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              className="form-control d-none"
                              id="taskImage"
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                if (e.target.files.length > 0) { // Check if files are selected
                                  setRetailer({
                                    ...retailer,
                                    retailer_image: e.target.files[0],
                                  });
                                }
                              }}
                            />
                          </div>

                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {retailer.retailer_image != "" ? (
                                <img
                                  src={URL.createObjectURL(
                                    retailer.retailer_image
                                  )}
                                  id="product-img"
                                  alt="product-img"
                                  className="150px"
                                  width={"150px"}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="CandidateName-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>Address</div>
                      </Label>
                      <CKEditor
                        rows={1}
                        editor={ClassicEditor}
                        onChange={(e, editor) => {
                          setRetailer({
                            ...retailer,
                            retailer_address: editor.getData(),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <button
                ref={submitButtonRef}
                name="close"
                id="close"
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  SubmitData();
                }}
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                Save
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>

            </div>
          </Form>
        </span>
      </Modal>
    </div>
  );
};

export default RetailerAdd;
