import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../../Components/Common/DeleteModal";
import UserUpdate from "./UserUpdate";
import AuthUser from "../../../helpers/Authuser";
import { useEffect } from "react";
import UserCreateModal from "./UserCreateModal";
import UserView from "./UserView";
import D_img from "../../D_img";
import InfiniteScroll from "react-infinite-scroll-component";
import { IMG_API_URL } from "../../../helpers/url_helper";
import jsPDF from "jspdf";
import { useParams } from "react-router-dom";
import Import from "./Import";

const UsersList = () => {
  const { status } = useParams();
  const [filterData, setFilterData] = useState({
    employee_name: "",
  })
  const [modalStatesimport, setModalStatesimport] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);
  const [viewModalState, setviewModalState] = useState(false);
  const { http, permission } = AuthUser();
  //   Delete Aleart
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, SetID] = useState();
  const [dstatus, Setdstatus] = useState();
  const onClickDelete = (data) => {
    // console.log(data.status);
    SetID(data.id);
    Setdstatus(data.status);
    setDeleteModal(true);
  };
  const handleDeleteOrder = (data) => {
    if (data._reactName === "onClick") {
      http
        .get(`/user/delete/${ID}/${dstatus}`)
        .then(function (response) {
          if (response.data.status === 0) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          Setcounts(counts - 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    setEmployee([]);
    setDeleteModal(false);
  };

  //   end Alert
  const handleCallback = (data, status) => {
    setModalStates(false);
    setUpdateModalStates(false); setModalStatesimport(false);
    setEmployee([]);
    if (status == 1) {
      toast.success(data);

    } else if (status == 2) {
      toast.warn(data);
      // toast.error(data);

    }
    Setcounts(counts + 1);
    // Setcounts(counts + 1);
  };
  const [NoMore, SetNoMore] = useState(true);

  const [counts, Setcounts] = useState(1);
  const [Employee, setEmployee] = useState([]);
  // console.log(Employee);
  useEffect(() => {
    http
      .get(`/user/list`)
      .then(function (response) {
        if (response.data.length > 0) {
          setEmployee(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        SetNoMore(false);
      });
  }, [counts]);
  const fetchData = () => {
    Setcounts(counts + 1);
  };
  // Edit Data
  const [FindData, SetFind] = useState([]);
  const EditUpdate = (index) => {
    let FindArray = Employee.filter((_, i) => i === index);
    SetFind(FindArray[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };
  const ViewData = (index) => {
    let FindArray = Employee.filter((_, i) => i === index);
    SetFind(FindArray[0]);
    setviewModalState(!viewModalState);
  };
  // shortcut to get add form
  // shortcuts for opening add form
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.altKey && event.key === "a") ||
        (event.altKey && event.key === "A")
      ) {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const generatePDF = () => {
    const doc = new jsPDF('landscape');

    // Set table headers
    const headers = [
      "#",
      "Profile",
      "Empoyee Name",
      "Employee Email",
      "Employee Number",
      "Employee Role",
      "Status",
    ];
    // Set table rows
    const data = Employee.filter(data => (status > 0 ? data.status == status : data.status != 0) && data.name.toLowerCase().includes(filterData.employee_name.toLowerCase())).map((item, index) => [
      index + 1,
      item.profile_photo,
      item.name,
      item.email,
      item.mobile_no,
      item.role_name,
      item.status == 1 ? 'Active' : item.status == 2 ? 'Deactive' : ''
    ]);

    // Set table style
    doc.setFontSize(12);
    doc.text("userList", 14, 15);
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 20,
    });


    if (status == 1) {

      doc.save("Activateemployee.pdf");
    } else if (status == 2) {
      doc.save("Deactivateemployee.pdf");
    } else {
      doc.save("Allemployee.pdf");
    }


    toast.success("PDF generated successfully!");
  };

  const convertToCSVexcel = () => {
    let csvContent = "#,Profile, Employee Name,Employee Email,Mobile Number,Employee Role,Status";

    Employee.filter(data => (status > 0 ? data.status == status : data.status != 0) && data.name.toLowerCase().includes(filterData.employee_name.toLowerCase())).forEach((item, index) => {
      csvContent += `\n${index + 1},"${item.profile_photo}","${item.name}","${item.email}","${item.mobile_no}","${item.role_name}","${item.status == 1 ? 'Active' : item.status == 2 ? 'Deactive' : ''}"`;
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);

      if (status == 1) {
        link.setAttribute("download", "Activateemployee.csv");
      } else if (status == 2) {
        link.setAttribute("download", "Deactivateemployee.csv");
      } else {
        link.setAttribute("download", "Allemployee.csv");
      }

      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    toast.success("CSV generated successfully!");
  };
  // const convertToCSVexcel = () => {
  //   let csvContent =
  //     "#,Profile, Employee Name,Employee Email,Mobile Number,Employee Role";

  //   Employee.filter(data => (status > 0 ? data.status == status : data.status != 0) && data.name.toLowerCase().includes(filterData.employee_name.toLowerCase())).forEach((item, index) => {
  //     csvContent += `${index + 1},"${item.profile_photo}","${item.name
  //       }","${item.email}","${item.mobile_no}","${item.role_name
  //       }",,"${item.status == 1 ? 'Active' : item.status == 2 ? 'Deactive' : ''
  //       }"\n`;
  //   });

  //   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  //   const link = document.createElement("a");
  //   if (link.download !== undefined) {
  //     const url = URL.createObjectURL(blob);
  //     link.setAttribute("href", url);
  //     link.setAttribute("download", "userList.csv");
  //     link.style.visibility = "hidden";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  //   toast.success("CSV generated successfully!");
  // };
  return (
    <div className="page-content">

      {modalStatesimport === true ? (
        <Import
          modalStates={modalStatesimport}
          setModalStates={() => {
            setModalStatesimport(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">Employee </h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">

                      <div>
                        <div className="fw-bold ">Employee Name</div>
                        <input type="text" className="fw-bold form-control" placeholder="Employee Name" onChange={(e) => {
                          setFilterData({
                            ...filterData,
                            employee_name: e.target.value
                          })
                        }} />
                      </div>
                      <div className="mt-3">
                        {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "1") && (<button
                          type="button"
                          className="btn fw-bold btn-success add-btn"
                          id="create-btn"
                          onClick={() => setModalStates(!modalStates)}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Employee
                        </button>)}
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn mt-3 btn-info"
                          onClick={() => setModalStatesimport(!modalStatesimport)}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Import Data
                        </button>
                      </div>
                      <div className="mt-3">

                        <button
                          type="button"
                          className="btn fw-bold btn-info add-btn"
                          id="create-btn"
                          onClick={convertToCSVexcel}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Export
                        </button>
                      </div>
                      <div className="mt-3">

                        <button
                          type="button"
                          className="btn fw-bold btn-danger add-btn"
                          id="create-btn"
                          onClick={generatePDF}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          PDF
                        </button>
                      </div>
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "2") && (
                    <div className=" table-responsive">
                      {/* <InfiniteScroll
                      dataLength={Employee.length}
                      next={fetchData}
                      hasMore={NoMore}
                    > */}
                      <table
                        role="table"
                        className="align-middle  table-nowrap table table-hover"
                      >
                        <thead className="table-light text-muted text-uppercase">
                          <tr>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              #
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Profile
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Name
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Email
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Mobile Number
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Role
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Status
                            </th>
                            {(permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "4") || permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "3")) && (

                              <th>Action</th>)}
                          </tr>
                        </thead>
                        <tbody>
                          {Employee.filter(data => (status > 0 ? data.status == status : data.status != 0) && data.name.toLowerCase().includes(filterData.employee_name.toLowerCase())).map((item, index) => (
                            <tr key={index}>
                              <td>
                                <a
                                  className="fw-medium link-primary"
                                  href="/apps-ecommerce-order-details"
                                >
                                  {index + 1}
                                </a>
                              </td>
                              <td>
                                {item.profile_photo !== "/user/null" ? (
                                  <img
                                    src={IMG_API_URL + item.profile_photo}
                                    alt={item.profile_photo}
                                    height={"80px"} width={"80px"}
                                  />
                                ) : (
                                  <D_img height={"80px"} width={"80px"} />
                                )}
                              </td>
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              <td>{item.mobile_no}</td>
                              <td>{item.role_name}</td>
                              <td>{item.status === 1 ? <div className="badge bg-success" >
                                Active
                              </div> : <div className="badge bg-danger">Deactive</div>}</td>
                              {(permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "4") || permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "3")) && (

                                <td>
                                  <ul className="list-inline hstack gap-2 mb-0">
                                    {/* <li className="list-inline-item edit">
                                  <button
                                    className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                    onClick={() => ViewData(index)}
                                  >
                                    <i className="ri-eye-fill fs-16" />
                                  </button>
                                </li> */}
                                    {item.status === 1 ?
                                      <>
                                        {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "3") && (
                                          <li className="list-inline-item edit">
                                            <button
                                              className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                              onClick={() => EditUpdate(index)}
                                            >
                                              <i className="ri-pencil-fill fs-16" />
                                            </button>
                                          </li>)}
                                        {permission.find(permission => permission.permission_category === "EMPLOYEE" && permission.permission_path === "4") && (
                                          <li className="list-inline-item">
                                            <button
                                              onClick={() =>
                                                onClickDelete({
                                                  id: item.uid,
                                                  status: item.status,
                                                })
                                              }
                                              className="text-danger d-inline-block remove-item-btn  border-0 bg-transparent"
                                            >
                                              <i className="ri-delete-bin-5-fill fs-16" />
                                            </button>
                                          </li>)}
                                      </>
                                      : <li className="list-inline-item">
                                        <button
                                          onClick={() =>
                                            onClickDelete({
                                              id: item.uid,
                                              status: item.status,
                                            })
                                          }
                                          className="text-warninng d-inline-block remove-item-btn  border-0 bg-transparent"
                                        >
                                          <i className=" bx bx-recycle  fs-16" />
                                        </button>
                                      </li>}
                                  </ul>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* </InfiniteScroll> */}
                    </div>)}
                </div>

                {modalStates === true ? (
                  <UserCreateModal
                    modalStates={modalStates}
                    setModalStates={() => {
                      setModalStates(false);
                    }}
                    checkchang={handleCallback}
                  />
                ) : (
                  ""
                )}
                {UpdatemodalStates === true ? (
                  <UserUpdate
                    modalStates={UpdatemodalStates}
                    setModalStates={() => {
                      setUpdateModalStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {viewModalState === true ? (
                  <UserView
                    modalStates={viewModalState}
                    setModalStates={() => {
                      setviewModalState(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UsersList;
