import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Form,
  Button,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomInput from "../../Unit/Input";
import { useRef } from "react";
import Select from "react-select";
import AuthUser from "../../../helpers/Authuser";
import ShiftAdd from "../../Shift Slots/ShiftAdd";
import RoleCreateModal from "../Roles/RoleCreateModal";
import Flatpickr from "react-flatpickr";
const UserCreateModal = (props) => {
  const [modal, setModal] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const { https, http } = AuthUser();
  const [modalStatess1, setModalStatess1] = useState(false);
  const [modalStatess, setModalStatess] = useState(false);
  const [counts, Setcounts] = useState(1);
  const [ActiveShift, setActiveShift] = useState(false);
  const [activeRole, setActiveRole] = useState(false);
  const [EmployeeShift, setEmployeeShift] = useState();
  const [EmployeeRole, setEmployeeRole] = useState();
  const [ManageShift, setManageShift] = useState(0);
  const [ManageRole, setManageRole] = useState(0);

  const handleCallback = (data) => {
    Setcounts(counts + 1);
    setManageRole(1);
    setManageShift(1);
    toast.success(data);
    setModalStatess(false);
    setModalStatess1(false);
  };
  const [EmployeeAdhar, SetEmployeeAdhar] = useState("");
  const [EmployeeProfile, SetEmployeeProfile] = useState("");
  const [EmployeePan, SetEmployeePan] = useState("");
  const [EmployeeQrCode, SetEmployeeQrCode] = useState("");
  const [employeeData, setemployeeData] = useState({


    u_type: 2,
    name: '',
    address: '',
    user_role_id: '',
    date_of_joining: null,
    last_experience: '',
    last_working_company: '',
    last_company_salary: '',
    shift_id: '',
    salary: '',
    mobile_no: '',
    emergency_contact: '',
    email: '',
    password: '',
    aadhar_no: '',
    pan_no: '',
    user_upi: '',
    pan_photo: '',
    bank_passbook_photo: '',
    aadhar_photo: '',
    profile_photo: ''


  });

  useEffect(() => {

    http
      .get("/role/list")
      .then(function (response) {
        if (response.data.length == 0) {
          setActiveRole(false);
        } else {
          setEmployeeRole(response.data);
          setActiveRole(true);
          setemployeeData(() => ({
            ...employeeData,
            user_role_id:
              ManageRole == 0
                ? response.data[0].role_id
                : response.data[response.data.length - 1].role_id,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });


    http
      .get("/shift/list")
      .then(function (response) {
        if (response.data.length == 0) {
          setActiveShift(false);
        } else {
          setEmployeeShift(response.data);
          setActiveShift(true);
          setemployeeData(() => ({
            ...employeeData,
            shift_id:
              ManageShift == 0
                ? response.data[0].shift_id
                : response.data[response.data.length - 1].shift_id,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [counts + 1]);

  const getSelectedCityValue = (e) => {
    setemployeeData({ ...employeeData, shift_id: e.value });
  };
  const getSelectedRoleValue = (e) => {
    setemployeeData({ ...employeeData, user_role_id: e.value });
  };
  useEffect(() => {
    setModal(true);
    toggle();
  }, [props.modalStatess1]);
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStatess]);

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };



  // GETTING ROLES LIST
  const getRolesList = async () => {
    try {
      const apiResponse = await https.get("/role/list");
      setRolesList(apiResponse.data);
    } catch (error) {
      console.log(error);
    }
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});
  const [mobcheck, setCheckStatus1] = useState({});
  const [emailcheck, setCheckStatus2] = useState({});
  const [passcheck, setCheckStatus3] = useState({});
  const [profilecheck, setCheckStatus4] = useState({});


  const [name, setMsg] = useState("");
  const [mob, setMsg0] = useState("");
  const [email, setMsg2] = useState("");
  const [pass, setMsg3] = useState("");
  const [msg1, setMsg1] = useState("");
  const [isButtonClicked, setisButtonClicked] = useState(false);

  const [valid, setValid] = useState(0);
  const SubmitData = () => {

    if (employeeData.name === "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setisButtonClicked(false);
      setMsg("Name cannot be empty!");
    }
    else if (employeeData.email === "") {
      setCheckStatus2({
        borderColor: "red",
        borderStyle: "groove",
      });
      setisButtonClicked(false);
      setMsg2("Email cannot be empty!");
    }
    else if (employeeData.password === "") {
      setCheckStatus3({
        borderColor: "red",
        borderStyle: "groove",
      });
      setisButtonClicked(false);
      setMsg3("Password cannot be empty!");
    }

    else if (employeeData.mobile_no === "") {
      setCheckStatus1({
        borderColor: "red",
        borderStyle: "groove",
      });
      setisButtonClicked(false);
      setMsg0("Mobile cannot be empty!");
    }
    else if (employeeData.salary === "") {
      setValid(1);
    }
    else {
      https
        .post("/user/store", employeeData)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
          // setisButtonClicked(true);
          setModal(false)
        })
        .catch(function (error) {
          console.log("Error", error);
        });
    }
  };
  const handleEmployee = (e) => {
    setCheckStatus({});
    setMsg("");
    setemployeeData({ ...employeeData, [e.target.name]: e.target.value });
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        Close();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <Modal id="showModal" size="xl" isOpen={modal} toggle={toggle} style={{ marginTop: '5px', paddingTop: '10px' }} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Add Employee
        </ModalHeader>
        <div className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              // SubmitData();
              return false;
            }}
            action="#"
          >

            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <div className="mb-3">
                  <Row>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  "
                      >
                        <div>
                          Employee Name <span className="text-danger">*</span>{" "}
                        </div>
                        {employeeData.name === "" && (
                          <div style={{ color: "red", fontSize: '10px' }}>{name}</div>)}
                      </Label>
                      <CustomInput
                        checkNameStatus={checkNameStatus}
                        id="role-name-field"
                        className="form-control fw-bold"
                        placeholder="Employee Name"
                        type="text"
                        name="name"
                        onChange={handleEmployee}
                      />
                    </Col>

                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  "
                      >
                        <div> Mobile<span className="text-danger fw-bold"> *</span>{" "}</div>
                        {employeeData.mobile_no === "" && (
                          <div style={{ color: "red", fontSize: '10px' }}>{mob}</div>)}
                      </Label>
                      <Input
                        type="text"
                        checkNameStatus={mobcheck}

                        value={employeeData ? employeeData.mobile_no : ""}
                        onChange={(e) => {
                          // Remove non-numeric characters
                          const input = e.target.value.replace(/\D/g, '');

                          // Apply validation for 10-digit number
                          if (input.length <= 10) {
                            setemployeeData({
                              ...employeeData,
                              mobile_no: input,
                            });
                          }

                          // Clear input on backspace
                          if (e.nativeEvent.inputType === 'deleteContentBackward' && input.length === 0) {
                            setemployeeData({
                              ...employeeData,
                              mobile_no: '',
                            });
                          }
                        }}
                        className="fw-bold"
                        placeholder="Mobile Number"
                      />

                    </Col>

                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  "
                      >
                        <div> Email<span className="text-danger fw-bold"> *</span>{" "}</div>  {employeeData.email === '' && (
                          <div style={{ color: "red", fontSize: '10px' }}>{email}</div>)}
                      </Label>
                      <Input
                        checkNameStatus={emailcheck}
                        name="email"
                        id="email"
                        onChange={(e) => handleEmployee(e)}
                        className="form-control fw-bold"
                        placeholder="Email address"
                        type="email"
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>  Password<span className="text-danger fw-bold"> *</span>{" "}</div>
                        {employeeData.password === '' && (
                          <div style={{ color: "red", fontSize: '10px' }}>{pass}</div>)}
                      </Label>
                      <Input
                        checkNameStatus={passcheck}
                        name="password"
                        id="password"
                        onChange={(e) => handleEmployee(e)}
                        className="form-control fw-bold"
                        placeholder="Password"
                        type="password"
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div> Salary <span className="text-danger fw-bold"> *</span> </div>
                        <div className="text-danger">{valid === 1 ? "salary cannot be empty!" : ""}</div>
                      </Label>
                      <Input
                        name="salary"
                        id="salary"

                        onChange={(e) => handleEmployee(e)}
                        className="form-control fw-bold"
                        placeholder="Salary"
                        type="text"
                      />
                    </Col>

                    <Col lg={3}>
                      <Label
                        htmlFor="customername-field"
                        className="form-label mt-3 fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Role <span className="text-danger fw-bold"> *</span>
                        </div>
                        <a
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => setModalStatess(!false)}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </a>
                      </Label>
                      {ActiveShift ? (
                        <Select
                          onChange={getSelectedRoleValue}
                          options={
                            EmployeeRole
                              ? EmployeeRole.map((group) => ({
                                value: group.role_id,
                                label: group.role_name,
                              }))
                              : []
                          }
                          name="user_role_id"
                          id="user_role_id"
                          className="fw-bold"
                          placeholder={
                            ManageRole === 0
                              ? EmployeeShift[0].role_name
                              : EmployeeShift[EmployeeShift.length - 1].role_name
                          }
                        />
                      ) : (
                        <Input
                          type="text"
                          readOnly
                          className="form-control fw-bold"
                          style={{ color: "red" }}
                          value="First Fill the Role *"
                          placeholder=""
                        />
                      )}

                    </Col>
                    <Col lg={3}>
                      <div className="mb-4 mt-3">
                        <h5 className="fs-15 mb-1">Profile </h5>

                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="empoyeeProfile"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title=""
                                data-bs-original-title="Select Image"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i className="ri-image-fill"></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="empoyeeProfile"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    profile_photo: e.target.files[0],
                                  });
                                  SetEmployeeProfile(e.target.files[0]);
                                }}
                              />
                            </div>'    '
                            <div className="avatar-lg">
                              <div className="avatar-title bg-light rounded">
                                {EmployeeProfile ? (
                                  <img
                                    src={URL.createObjectURL(EmployeeProfile)}
                                    id="product-img"
                                    alt="product-img"

                                    width={"100px"}
                                    height={"100px"}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        Address
                      </Label>
                      <textarea
                        name="address"
                        id="address"
                        placeholder="Employee address"
                        className="form-control fw-bold"
                        onChange={handleEmployee}
                      ></textarea>
                    </Col>

                  </Row>
                </div>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <button
                ref={submitButtonRef}
                name="submit"
                id="submit"
                type="submit"
                className="btn btn-primary"
                onClick={() => SubmitData()}
                disabled={isButtonClicked}
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                Save
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>

            </div>
          </Form>
        </div>
      </Modal >

      {modalStatess1 === true ? (
        <ShiftAdd
          modalStates={modalStatess1}
          setModalStates={() => {
            setModalStatess1(false);
          }}
          checkchang={handleCallback}
        />
      ) : modalStatess === true ? (
        <RoleCreateModal
          modalStates={modalStatess}
          setModalStates={() => {
            setModalStatess(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
    </div >
  );
};

export default UserCreateModal;
