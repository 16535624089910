import React, { useRef, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import RetailerAdd from "./RetailerAdd";
import RetailerEdit from "./RetailerEdit";
import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import { IMG_API_URL } from "../../helpers/url_helper";
import InfiniteScroll from "react-infinite-scroll-component";
import D_img from "../D_img";
import { useParams } from 'react-router-dom';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Import from "../UserManagement/Users/Import";

const RetailerList = () => {
  const [modalStates, setModalStates] = useState(false);
  const [UpdatemodalStates, setUpdateModalStates] = useState(false); const [modalStatesimport, setModalStatesimport] = useState(false);
  const { http, permission } = AuthUser();
  const { status } = useParams();
  const [counts, Setcounts] = useState(1);
  const [RetailerData, SetRetailerData] = useState([]);
  // infinity
  const [Pages, SetPages] = useState(1);
  const [NoMore, SetNoMore] = useState(true);
  useEffect(() => {
    document.title = "Retailer | E-DOC";

    http
      .get(`/retailer/list`)
      .then(function (response) {
        SetRetailerData(response.data);

      })
      .catch(function (error) {
        console.log(error);
        SetNoMore(false);
      });
  }, [counts]);
  const fetchData = () => {
    Setcounts(counts + 1);
  };
  //   Delete Aleart
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, SetID] = useState();
  const onClickDelete = (data) => {
    SetID(data);
    setDeleteModal(true);
  };
  const handleDeleteOrder = (data) => {
    if (data._reactName == "onClick") {
      http
        .delete(`/retailer/delete/${ID}`)
        .then(function (response) {
          if (response.data.status == 0) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          Setcounts(counts + 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    SetRetailerData([]);
    SetPages(1);
    setDeleteModal(false);
  };

  // shortcuts for opening add form
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "a" || event.altKey && event.key === "A") {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //   end Alert
  const handleCallback = (data, status) => {
    SetRetailerData([]); setModalStatesimport(false);
    SetPages(1);
    if (status == 1) {
      toast.success(data);
      Setcounts(counts + 1);
    } else {
      toast.warn(data);
      Setcounts(counts + 1);
    }
    setModalStates(false);
    setUpdateModalStates(false);
  };
  // Edit Data
  const [FindData, SetFind] = useState([]);
  const EditUpdate = (index) => {
    let FindArray = RetailerData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };
  const [filterData, setFilterData] = useState({
    retailer_name: ""
  })


  const tableRef = useRef(null);
  const downloadPDF = () => {
    const table = tableRef.current;
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    const pdf = new jsPDF();
    pdf.autoTable({ html: table });

    const blob = pdf.output("blob");
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "retailer_list.pdf";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadCSV = () => {
    const table = tableRef.current;
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    const csvContent = Array.from(table.querySelectorAll("tr"))
      .map((row) =>
        Array.from(row.children)
          .map((cell) => `"${cell.innerText.trim()}"`)
          .join(",")
      )
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "retailer_list.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (

    <div className="page-content">
      {modalStatesimport === true ? (
        <Import
          modalStates={modalStatesimport}
          setModalStates={() => {
            setModalStatesimport(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {permission.find(permission => permission.permission_category === "RETAILER" && permission.permission_path === "2") && (
        <table
          style={{ display: "none" }}
          ref={tableRef}
          role="table"
          className="align-middle table-nowrap table table-hover"
        >
          <thead className="table-light text-muted text-uppercase">
            <tr>
              <th
                title="Toggle SortBy"
                style={{ cursor: "pointer" }}
              >
                #
              </th>
              <th
                title="Toggle SortBy"
                style={{ cursor: "pointer" }}
              >
                Image
              </th>
              <th
                title="Toggle SortBy"
                style={{ cursor: "pointer" }}
              >
                Retailer Name
              </th>
              <th
                title="Toggle SortBy"
                style={{ cursor: "pointer" }}
              >
                Shop Name
              </th>
              <th
                title="Toggle SortBy"
                style={{ cursor: "pointer" }}
              >
                Phone Number
              </th>

              <th
                title="Toggle SortBy"
                style={{ cursor: "pointer" }}
              >
                Email
              </th>
              <th
                title="Toggle SortBy"
                style={{ cursor: "pointer" }}
              >
                Status
              </th>

            </tr>
          </thead>
          <tbody>
            {RetailerData.filter(item => (status > 0 ? item.status == status : item.status != 0) && item.name.includes(filterData.retailer_name)).map((item, index) => (
              <tr key={index}>
                <td>
                  <a
                    className="fw-medium link-primary"
                    href="/apps-ecommerce-order-details"
                  >
                    {index + 1}
                  </a>
                </td>
                <td>
                  {item.profile_photo ?
                    <img src={`${IMG_API_URL + "/user/" + item.profile_photo}`} className="" height={"80px"} width={"80px"} />
                    :
                    <D_img />
                  }
                </td>
                <td>{item.name}</td>
                <td>{item.business_name}</td>
                <td>{item.mobile_no}</td>
                <td>{item.email}</td>
                <td>{item.status === 1 ? <div className="badge bg-success" >
                  Approved
                </div> : <div className="badge bg-danger">Rejected</div>}</td>

              </tr>
            ))}
          </tbody>
        </table>)}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">Retailer  List</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <div>

                        <input className="fw-bold form-control" placeholder="Retailer Name" onChange={(e) => {
                          setFilterData({

                            ...filterData,
                            retailer_name: e.target.value
                          })
                        }} />
                      </div>
                      <div>
                        {permission.find(permission => permission.permission_category === "RETAILER" && permission.permission_path === "1") && (
                          <button
                            type="button"
                            className="btn fw-bold btn-success add-btn"
                            id="create-btn"
                            onClick={() => setModalStates(!modalStates)}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            Add Retailer
                          </button>
                        )}
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn   btn-info"
                          onClick={() => setModalStatesimport(!modalStatesimport)}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Import Data
                        </button>
                      </div>
                      <div className="">
                        <div className=" btn btn-info"
                          onClick={downloadCSV}
                        >CSV</div>
                      </div>
                      <div className="">
                        <div className=" btn btn-primary" onClick={downloadPDF}> PDF</div>
                      </div>

                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>

                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  <InfiniteScroll
                    dataLength={RetailerData.length}
                    next={fetchData}
                    hasMore={NoMore}
                  >
                    {permission.find(permission => permission.permission_category === "RETAILER" && permission.permission_path === "2") && (
                      <table
                        role="table"
                        className="align-middle table-nowrap table table-hover"
                      >
                        <thead className="table-light text-muted text-uppercase">
                          <tr>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              #
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Image
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Retailer Name
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Shop Name
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Phone Number
                            </th>

                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Email
                            </th>
                            <th
                              title="Toggle SortBy"
                              style={{ cursor: "pointer" }}
                            >
                              Status
                            </th>
                            {(permission.find(permission => permission.permission_category === "RETAILER" && permission.permission_path === "4") || permission.find(permission => permission.permission_category === "RETAILER" && permission.permission_path === "3")) && (
                              <th>Action</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {RetailerData.filter(item => (status > 0 ? item.status == status : item.status != 0) && item.name.includes(filterData.retailer_name)).map((item, index) => (
                            <tr key={index}>
                              <td>
                                <a
                                  className="fw-medium link-primary"
                                  href="/apps-ecommerce-order-details"
                                >
                                  {index + 1}
                                </a>
                              </td>
                              <td>
                                {item.profile_photo ?
                                  <img src={`${IMG_API_URL + "/user/" + item.profile_photo}`} className="" height={"80px"} width={"80px"} />
                                  :
                                  <D_img />
                                }
                              </td>
                              <td>{item.name}</td>
                              <td>{item.business_name}</td>
                              <td>{item.mobile_no}</td>
                              <td>{item.email}</td>
                              <td>{item.status === 1 ? <div className="badge bg-success" >
                                Approved
                              </div> : <div className="badge bg-danger">Rejected</div>}</td>

                              {(permission.find(permission => permission.permission_category === "RETAILER" && permission.permission_path === "4") || permission.find(permission => permission.permission_category === "RETAILER" && permission.permission_path === "3")) && (

                                <td>
                                  <ul className="list-inline hstack gap-2 mb-0">
                                    {permission.find(permission => permission.permission_category === "RETAILER" && permission.permission_path === "3") && (
                                      <li className="list-inline-item edit">
                                        <button
                                          className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                          onClick={() => EditUpdate(index)}
                                        >
                                          <i className="ri-pencil-fill fs-16" />
                                        </button>
                                      </li>)}
                                    {permission.find(permission => permission.permission_category === "RETAILER" && permission.permission_path === "4") && (
                                      <li className="list-inline-item">
                                        {item.city_id != 1 ? (
                                          <button
                                            onClick={() =>
                                              onClickDelete(item.uid)
                                            }
                                            className="text-danger d-inline-block remove-item-btn  border-0 bg-transparent"
                                          >
                                            <i className="ri-delete-bin-5-fill fs-16" />
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </li>
                                    )}
                                  </ul>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>)}
                  </InfiniteScroll>
                </div>

                {modalStates === true ? (
                  <RetailerAdd
                    modalStates={modalStates}
                    setModalStates={() => {
                      setModalStates(false);
                    }}
                    checkchang={handleCallback}
                  />
                ) : (
                  ""
                )}
                {UpdatemodalStates === true ? (
                  <RetailerEdit
                    modalStates={UpdatemodalStates}
                    setModalStates={() => {
                      setUpdateModalStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RetailerList;
