import React, { useRef, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import RechargeAdd from "./RechargeAdd";
import RechargeEdit from "./RechargeEdit";
import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import { IMG_API_URL } from "../../helpers/url_helper";
import InfiniteScroll from "react-infinite-scroll-component";
import D_img from "../D_img";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const RechargeList = () => {
  const { https, http, user } = AuthUser();

  const [retailer, setRetailer] = useState([]);
  const getRetailer = async () => {
    http
      .get("/retailer/list")
      .then((res) => {
        setRetailer(res.data)
      })
      .catch((err) => {
        console.log(err);
      })
  }
  const [counts, Setcounts] = useState(1);
  const [RechargeData, SetRechargeData] = useState([]);
  const [Pages, SetPages] = useState(1);
  const [NoMore, SetNoMore] = useState(true);
  const [filterData, setFilterData] = useState({
    retailer_name: "",
    retailer_mobile: "",
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10)
  });

  useEffect(() => {
    document.title = "Recharge | E-DOC";
    getRetailer();
    http.get(`/recharge/list`)
      .then(function (response) {
        const filteredData = user.user.u_type === 3
          ? response.data.filter((item) => item.recharge_retailer_id == user.user.uid)
          : response.data;

        SetRechargeData(filteredData);
      })
      .catch(function (error) {
        console.log(error);
        SetNoMore(false);
      });
  }, [counts]);



  const fetchData = () => {
    Setcounts(counts + 1);
  };

  // Delete Alert
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, SetID] = useState();

  const onClickDelete = (data) => {
    SetID(data);
    setDeleteModal(true);
  };

  const handleDeleteOrder = (data) => {
    if (data._reactName == "onClick") {
      http.delete(`/city/delete/${ID}`)
        .then(function (response) {
          if (response.data.status == 0) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          Setcounts(counts + 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    SetRechargeData([]);
    SetPages(1);
    setDeleteModal(false);
  };

  const handleCallback = (data, status) => {
    SetRechargeData([]);
    SetPages(1);
    if (status == 1) {
      toast.success(data);
      Setcounts(counts + 1);
    } else {
      toast.warn(data);
      Setcounts(counts + 1);
    }
  };

  const tableRef = useRef(null);
  const downloadPDF = () => {
    const table = tableRef.current;
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    const pdf = new jsPDF();
    pdf.autoTable({ html: table });

    const blob = pdf.output("blob");
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "recharge_list.pdf";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadCSV = () => {
    const table = tableRef.current;
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    const csvContent = Array.from(table.querySelectorAll("tr"))
      .map((row) =>
        Array.from(row.children)
          .map((cell) => `"${cell.innerText.trim()}"`)
          .join(",")
      )
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "recharge_list.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="page-content">
      <table
        ref={tableRef}
        style={{ display: "none" }}
        role="table"
        className="align-middle table-nowrap table table-hover"
      >
        <thead className="table-light text-muted text-uppercase">
          <tr>
            <th
              title="Toggle SortBy"
              style={{ cursor: "pointer" }}
            >
              #
            </th>
            <th
              title="Toggle SortBy"
              style={{ cursor: "pointer" }}
            >
              Date
            </th>
            <th
              title="Toggle SortBy"
              style={{ cursor: "pointer" }}
            >
              Retailer Name
            </th>
            <th
              title="Toggle SortBy"
              style={{ cursor: "pointer" }}
            >
              Phone Number
            </th>

            <th
              title="Toggle SortBy"
              style={{ cursor: "pointer" }}
            >
              Amount
            </th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {
            RechargeData.filter(item => (
              (filterData.retailer_name > 0 ? item.uid == filterData.retailer_name : true) &&
              item.mobile_no.includes(filterData.retailer_mobile) &&
              (new Date(item.recharge_date) >= new Date(filterData.startDate) &&
                new Date(item.recharge_date) <= new Date(filterData.endDate))
            )).map((item, index) => (
              <tr key={index} className={index === 0 ? "blink-first-record" : ""}>
                <td>
                  <a className="fw-medium link-primary" href="/apps-ecommerce-order-details">
                    {index + 1}
                  </a>
                </td>
                <td>{item.recharge_date}</td>
                <td>{item.name}</td>
                <td>{item.mobile_no}</td>
                <td>{item.recharge_amount}</td>
                <td>{item.recharge_status == 1 ? <div className="">Successful</div> : <div className="">Failed</div>}</td>
              </tr>
            ))}

        </tbody>
      </table>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">Recharge  List</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <div >
                        <div className="fw-bold">Start Date</div>
                        <Flatpickr

                          className="form-control fw-bold "
                          options={{
                            dateFormat: "Y-m-d",
                            defaultDate: filterData.startDate,
                          }}
                          name="payment_date"
                          placeholder="Start date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];

                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day}`;

                            setFilterData({
                              ...filterData,
                              startDate: formattedDate,
                            });
                          }}
                        />
                      </div>
                      <div className="" >
                        <div className="fw-bold">End Date</div>
                        <Flatpickr
                          className="form-control fw-bold"
                          options={{
                            dateFormat: "Y-m-d",
                            defaultDate: filterData.endDate,
                          }}
                          name="payment_date"
                          placeholder="End date"
                          onChange={(selectedDates) => {
                            const selectedDate = selectedDates[0];

                            const year = selectedDate.getFullYear();
                            const month = (selectedDate.getMonth() + 1)
                              .toString()
                              .padStart(2, "0");
                            const day = selectedDate
                              .getDate()
                              .toString()
                              .padStart(2, "0");
                            const formattedDate = `${year}-${month}-${day}`;

                            setFilterData({
                              ...filterData,
                              endDate: formattedDate,
                            });
                          }}
                        />
                      </div>
                      {user.user.u_type !== 3 && (
                        <>
                          <div className="">
                            <div className="fw-bold">Retailer Name</div>
                            {/* <input className="form-control fw-bold" onChange={(e) => {
                              
                            }} placeholder="Retailer Name" /> */}
                            <Select
                              className="fw-bold"
                              placeholder={"Retailer Name"}
                              options={retailer.filter(item => item.u_type == 3).map((data, index) => ({
                                value: data.uid,
                                label: data.name,
                              }))}
                              onChange={(e) => {
                                setFilterData({
                                  ...filterData,
                                  retailer_name: e.value
                                })
                              }}
                            ></Select>
                          </div>
                          <div className="">
                            <div className="fw-bold">Phone Number</div>
                            <input className="form-control fw-bold" onChange={(e) => {
                              setFilterData({
                                ...filterData,
                                retailer_mobile: e.target.value
                              })
                            }} placeholder="Phone Number" />
                          </div>
                        </>)
                      }
                      <div className="mt-3">
                        <div className=" btn btn-info"
                          onClick={downloadCSV}
                        >CSV</div>
                      </div>
                      <div className="mt-3">
                        <div className=" btn btn-primary" onClick={downloadPDF}> PDF</div>
                      </div>
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>

                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  {/* <InfiniteScroll
                    dataLength={RechargeData.length}
                    next={fetchData}
                    hasMore={NoMore}
                  > */}
                  <table
                    role="table"
                    className="align-middle table-nowrap table table-hover"
                  >
                    <thead className="table-light text-muted text-uppercase">
                      <tr>
                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          #
                        </th>
                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          Date
                        </th>
                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          Retailer Name
                        </th>
                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          Phone Number
                        </th>

                        <th
                          title="Toggle SortBy"
                          style={{ cursor: "pointer" }}
                        >
                          Amount
                        </th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        RechargeData.filter(item => (
                          (filterData.retailer_name > 0 ? item.uid == filterData.retailer_name : true) &&
                          item.mobile_no.includes(filterData.retailer_mobile) &&
                          (new Date(item.recharge_date) >= new Date(filterData.startDate) &&
                            new Date(item.recharge_date) <= new Date(filterData.endDate))
                        )).map((item, index) => (
                          <tr key={index} className={index === 0 ? "blink-first-record" : ""}>
                            <td>
                              <a className="fw-medium link-primary" href="/apps-ecommerce-order-details">
                                {index + 1}
                              </a>
                            </td>
                            <td>{item.recharge_date}</td>
                            <td>{item.name}</td>
                            <td>{item.mobile_no}</td>
                            <td>{item.recharge_amount}</td>
                            <td>{item.recharge_status == 1 ? <div className="badge bg-success">Successful</div> : <div className="badge bg-danger">Failed</div>}</td>
                          </tr>
                        ))}

                    </tbody>
                  </table>
                  {/* </InfiniteScroll> */}
                </div>
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div >
  );
};

export default RechargeList;
