import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
//import logo
import logoSm from "../assets/images/logo/smalllogo.jpg";
import logoDark from "../assets/images/logo/logo.jpg";
import logoLight from "../assets/images/logo/logo.jpg";
import ajspireLogo from "../assets/images/ajspire.png";

//Import Components
import VerticalLayout from "./VerticalLayouts/index";
import TwoColumnLayout from "./TwoColumnLayout";
import { Container } from "reactstrap";
import HorizontalLayout from "./HorizontalLayout";
import AuthUser from "../helpers/Authuser";
import { IMG_API_URL } from "../helpers/url_helper";
import D_img from "../pages/D_img";

const Sidebar = ({ layoutType }) => {
  const { https, http } = AuthUser();
  const [businessProfileData, setBusinessProgilrData] = useState([]);
  useEffect(() => {
    http
      .get("/business-setting/list")
      .then(function (response) {
        if (response.data.length > 0) {
          console.log(response.data);
          // setBusinessStatus(true);
          setBusinessProgilrData(response.data[0]);
          console.log(response.data[0]);
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  }, []);
  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (
      document.documentElement.getAttribute("data-sidebar-size") === "sm-hover"
    ) {
      document.documentElement.setAttribute(
        "data-sidebar-size",
        "sm-hover-active"
      );
    } else if (
      document.documentElement.getAttribute("data-sidebar-size") ===
      "sm-hover-active"
    ) {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    } else {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    }
  };
  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <Link to="/dashboard" className="logo logo-dark">
            <span className="logo-sm">
              {businessProfileData.length !== 0 && businessProfileData.business_logo !== "/business_setting/null" ? (
                <img src={IMG_API_URL + businessProfileData.business_logo} alt="" height={40} />

              ) : (
                <img src={process.env.PUBLIC_URL + '/logo.png'} alt="" height={40} />
              )}

            </span>
            <span className="logo-lg">{businessProfileData.business_logo !== "/business_setting/null" ? (
              <img src={IMG_API_URL + businessProfileData.business_logo} alt="" height={80} width={1000} />) : (
              <img src={process.env.PUBLIC_URL + '/logo.png'} alt="" height={80} width={1000} />
            )}
            </span>
          </Link>

          <Link to="/dashboard" className="logo logo-light">
            <span className="logo-sm">
              {businessProfileData.length !== 0 && businessProfileData.business_logo !== "/business_setting/null" ? (
                <img src={IMG_API_URL + businessProfileData.business_logo} alt="" height={40} />) : (
                <img src={process.env.PUBLIC_URL + '/logo.png'} alt="" height={40} />
              )}
            </span>
            <span className="logo-lg">
              {businessProfileData.length !== 0 && businessProfileData.business_logo !== "/business_setting/null" ? (
                <img src={IMG_API_URL + businessProfileData.business_logo} alt="" height={40} width={100} />


              ) : (
                <img src={process.env.PUBLIC_URL + '/logo.png'} alt="" height={40} width={100} />
              )}
            </span>
          </Link>
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>
        {layoutType === "horizontal" ? (
          <div id="scrollbar">
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <HorizontalLayout />
              </ul>
            </Container>
          </div>
        ) : layoutType === "twocolumn" ? (
          <React.Fragment>
            <TwoColumnLayout />
            <div className="sidebar-background"></div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SimpleBar id="scrollbar" className="h-100">
              <Container fluid>
                <div id="two-column-menu"></div>
                <ul className="navbar-nav" id="navbar-nav">
                  <VerticalLayout layoutType={layoutType} />
                </ul>
              </Container>
            </SimpleBar>
            <div className="sidebar-background"></div>
          </React.Fragment>
        )}
        {/* <div className="navbar-brand-box" style={{ marginTop: "-60px" }}>
          <a href="https://ajspire.com" className="logo logo-light">
            <span className="logo-sm">
              <img src={ajspireLogo} alt="" height={40} />
            </span>
            <span className="logo-lg">
              <img src={ajspireLogo} width={180} alt="" height={80} />
            </span>
          </a>
        </div> */}
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
