import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const ServicesAdd = (props) => {
  const { https, http, user } = AuthUser();
  const [modal, setModal] = useState(false);




  const [services, setServices] = useState({
    service_name: "",
    service_amount: "",
    service_image: "",
    service_description: "",
    documents: [],
  });


  // console.log(services);

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  const [documentData, setDocumentData] = useState([]);
  const getDocumentData = async () => {
    http
      .get("/document/list")
      .then((res) => {
        setDocumentData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
  }
  useEffect(() => {
    getDocumentData();
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [tempValid, settempValid] = useState(0);
  const SubmitData = () => {


    if (services.service_name === "") {
      settempValid(1);
    }
    else {
      // console.log("Data coming : ", services);
      https
        .post("/service/store", services)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        Close();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);




  return (
    <div>
      <Modal
        className="modal-xl"
        id="fullscreeexampleModal"
        isOpen={modal}
        size={"xl"}
        toggle={toggle}
        centered
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Create Services
        </ModalHeader>
        <span className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"

          >
            <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>
                  <Col lg={6}>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="CandidateName-field"
                            className="form-label fw-bold d-flex justify-content-between"
                          >
                            <div>
                              Service Name
                              <span style={{ color: "red" }}> *</span>
                            </div>
                            <div style={{ color: "red" }}>
                              {tempValid === 1 ? "Name cannot be empty!" : ""}
                            </div>
                          </Label>
                          <CustomInput
                            name="services_candidate_name"
                            id="CandidateName-field"
                            className="form-control fw-bold"
                            placeholder="Service Name"
                            type="text"
                            onChange={(e) => {
                              settempValid(0);
                              setServices({
                                ...services,
                                service_name: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlFor="CandidateName-field"
                            className="form-label fw-bold d-flex justify-content-between"
                          >
                            <div>Service Amount</div>
                          </Label>
                          <Input
                            type="text"
                            className="fw-bold "
                            placeholder="Service Amount"
                            onChange={(e) => {
                              setServices({
                                ...services,
                                service_amount: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </Col>
                      <Col
                        lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="CandidateName-field"
                            className="form-label fw-bold d-flex justify-content-between"
                          >
                            <div>Service Image</div>
                          </Label>
                          <div className="text-center">
                            <div className="position-relative d-inline-block">
                              <div className="position-absolute top-100 start-100 translate-middle">
                                <label
                                  htmlFor="taskImage"
                                  className="mb-0"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="right"
                                  title=""
                                  data-bs-original-title="Select Image"
                                >
                                  <div className="avatar-xs">
                                    <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                      <i className="ri-image-fill"></i>
                                    </div>
                                  </div>
                                </label>
                                <input
                                  className="form-control d-none"
                                  id="taskImage"
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => {
                                    if (e.target.files.length > 0) { // Check if files are selected
                                      setServices({
                                        ...services,
                                        service_image: e.target.files[0],
                                      });
                                    }
                                  }}
                                />
                              </div>

                              <div className="avatar-lg">
                                <div className="avatar-title bg-light rounded">
                                  {services.service_image != "" ? (
                                    <img
                                      src={URL.createObjectURL(
                                        services.service_image
                                      )}
                                      id="product-img"
                                      alt="product-img"
                                      className="200px"
                                      width={"200px"}
                                      height={"120px"}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label
                            htmlFor="CandidateName-field"
                            className="form-label fw-bold d-flex justify-content-between"
                          >
                            <div>Service Description</div>
                          </Label>
                          <CKEditor
                            rows={1}
                            editor={ClassicEditor}
                            onChange={(e, editor) => {
                              setServices({
                                ...services,
                                service_description: editor.getData(),
                              });
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Label
                      htmlFor="CandidateName-field"
                      className="form-label fw-bold d-flex justify-content-between"
                    >
                      <div>
                        Required Document's
                        <span style={{ color: "red" }}> *</span>
                      </div>

                    </Label>
                    <div className="row">
                      {documentData.map((data, index) => (
                        <div className="col-6 d-flex" key={index}>
                          <input type="checkbox" value={data.document_id} onClick={(e) => {
                            if (e.target.checked) {
                              setServices({
                                ...services,
                                documents: [...services.documents, e.target.value]
                              })
                            } else {
                              setServices({
                                ...services,
                                documents: services.documents.filter(id => id !== e.target.value)
                              });
                            }
                          }} className="m-2 p-2" />
                          <div className="fw-bold p-2 m-2">{data.document_name}<br></br> {data.document_description != '' ? (<div style={{ fontSize: '10px' }}>({data.document_description})</div>) : ''}</div><br />

                        </div>
                      ))}

                    </div>

                  </Col>
                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <button
                ref={submitButtonRef}
                name="close"
                id="close"
                type="submit"
                className="btn btn-primary"
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                Save
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>

            </div>
          </Form>
        </span>
      </Modal>
    </div>
  );
};

export default ServicesAdd;
