
import React from "react";
import { Col, Container, Row } from "reactstrap";
import packageJson from "../../package.json";
import { Link } from "react-router-dom";

const Footer = () => {
  const reactVersion = packageJson.version;
  return (
    <React.Fragment>
      <footer className="footer  fw-bold">
        <Container fluid>
          <Row>
            <Col sm={5}>
              Copyright © {new Date().getFullYear()}   . All rights
              reserved.
            </Col>
            <Col sm={5}>
              <div className="text-sm-end d-none d-sm-block  fw-bold">
                <a href='https://ajspire.com'>
                  Product by Ajspire Technologies Pvt. Ltd</a>
              </div>
            </Col>
            <Col sm={2}>
              <div className="text-sm-end d-none d-sm-block fw-bold">
                Version {reactVersion}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
